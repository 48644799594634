import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'progress-bar-circle',
    templateUrl: './progress-bar-circle.html',
    styleUrls: ['./progress-bar-circle.less']
})
export class ProgressBarCircleComponent implements OnInit {
    @Input() progress = 0;
    @Input() maxProgress = undefined;
    @Input() barStyles: object = {};
    @Input() preventProgressAnimation = false;

    radius = 15.91549430918954;

    styles = {
        size: '80px',
        ringColor: 'rgba(255, 255, 255, 0.1)',
        segmentColor: '#fff',
        percentColor: '#fff',
        fontSize: '0.5em',
        fontWeight: 'inherit',
        startsFrom: 'top',
        strokeWidth: '6'
    };

    get dasharrayNumber(): number {
        return 2 * Math.PI * this.radius; // 100
    }

    get dashoffsetNumber(): number {
        const completed = this.maxProgress ? (this.progress / this.maxProgress) : (this.progress / 100);
        return this.dasharrayNumber * (1 - completed);
    }

    ngOnInit() {
        this.styles = {
            ...this.styles,
            ...this.barStyles
        };
    }
}
