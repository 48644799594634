import { Component, Input } from '@angular/core';
import { trigger } from '@angular/animations';
import { TooltipPosition, TooltipType } from './custom-tooltip.directive';

@Component({
  selector: 'custom-tooltip',
  styleUrls: ['./custom-tooltip.component.less'],
  templateUrl: './custom-tooltip.component.html',
  animations: [
    trigger('tooltip', []),
  ],
})
export class CustomTooltipComponent {
  @Input() html = '';
  @Input() text = '';
  @Input() styles: object;
  @Input() position: TooltipPosition;
  @Input() type: TooltipType;
  @Input() persist: boolean;
  @Input() offset: number;

  get marginForOffset(): object {
    switch (this.position) {
      case 'top':
        return {'margin-bottom': `${this.offset}px`};
      case 'right':
        return {'margin-left': `${this.offset}px`};
      case 'bottom':
        return {'margin-top': `${this.offset}px`};
      case 'left':
        return {'margin-right': `${this.offset}px`};
    }
  }

  get stylesForInnerArea(): object {
    return Object.assign({}, this.styles, this.borderColor);
  }

  get borderColor(): object {
    if (this.styles['background-color']) {
      return { ['border-color']: this.styles['background-color'] };
    } else if (this.styles['border-color']) {
      return { ['border-color']: this.styles['border-color'] };
    } else {
      return { ['border-color']: '#f0f0ef' };
    }
  }

}
