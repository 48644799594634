import { Injectable } from '@node_modules/@angular/core';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class GroupService {
    constructor(private httpService: HttpService) {
    }

    getGroup(params) {
        return this.httpService.get('/api/services/app/Group/Get', {params});
    }

    getGroupsFiltered(params) {
        return this.httpService.get('/api/services/app/Group/GetGroupsFiltered', {params});
    }

    getGroupsShort(params) {
        return this.httpService.get('/api/services/app/Group/GetShortGroups', {params});
    }

    getGroupsFilteredCount(params) {
        return this.httpService.get('/api/services/app/Group/GetGroupsFilteredCount', {params});
    }

    getGroupAssociation(params) {
        return this.httpService.get('/api/services/app/Group/GetGroupAssociation', {params});
    }

    getGroupMaxAvailableUsersCount(params) {
        return this.httpService.get('/api/services/app/Group/GetGroupMaxAvailableUsersCount', {params});
    }

    getValidateGroupName(params) {
        return this.httpService.get('/api/services/app/Group/ValidateGroupName', {params});
    }

    getValidateGroupUsersMaxCount(params) {
        return this.httpService.get('/api/services/app/Group/ValidateGroupUsersMaxCount', {params});
    }

    postGroupCreate(params) {
        return this.httpService.post('/api/services/app/Group/Create', params);
    }

    putCreateOrUpdateGroupAssociation(params) {
        return this.httpService.put('/api/services/app/Group/CreateOrUpdateGroupAssociation', params);
    }

    putGroupUpdate(params) {
        return this.httpService.put('/api/services/app/Group/Update', params);
    }

    putUpdateGroupStatus(params) {
        return this.httpService.put('/api/services/app/Group/UpdateGroupStatus', params);
    }

    deleteGroup(id) {
        return this.httpService.delete(`/api/services/app/Group/Delete?GroupId=${id}`);
    }
}
