import { Component, Input, OnChanges, TemplateRef, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { LiveAnnouncer } from '@node_modules/@angular/cdk/a11y';

interface Column {
    id: string;
    title: string;
    cellTemplate?: TemplateRef<any>;
    columnTemplate?: TemplateRef<any>;
    sort: boolean;
}

@Component({
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.less'],
    selector: 'app-table'
})
export class TableComponent implements OnInit, OnChanges {
    @Input() arrayData: [];
    @Input() public outerPaginator: MatPaginator;
    @Input() columnsObjects: Column[];
    @Input() loading = false;

    @Output() sortEmiter = new EventEmitter();

    dataSource: MatTableDataSource<[]>;
    columnsToDisplay: string[];
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    constructor(private _liveAnnouncer: LiveAnnouncer) {
        this.dataSource = new MatTableDataSource(this.arrayData);
    }

    calculateColumnsToDisplay() {
        this.columnsToDisplay = this.columnsObjects.map(el => el.id);
    }

    announceSortChange(sortState) {
        this.sortEmiter.emit(sortState);
    }

    ngOnInit() {
        this.calculateColumnsToDisplay();
    }

    ngOnChanges() {
        this.dataSource.data = !this.loading ? this.arrayData : [];
        this.calculateColumnsToDisplay();
    }
}
