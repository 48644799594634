import { Injectable } from '@angular/core';
import {
  RoleServiceProxy, PagedResultDtoOfRoleDto, RoleDto, UserServiceProxy
} from '@shared/service-proxies/service-proxies';
import { AppAuthService } from '@shared/auth/app-auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private _roles: RoleDto[];
  private _impersonateTenantId: number;

  constructor(
    private roleServiceProxy: RoleServiceProxy,
    private userServiceProxy: UserServiceProxy,
    authService: AppAuthService
  ) {
    this._impersonateTenantId = authService.ImpersonateTenantId;
  }

  get roles(): RoleDto[] {
    return this._roles;
  }

  getRoleIdByName(name: string): number {
    const soughtRole = this._roles.find(role => role.name === name);
    return soughtRole && soughtRole.id;
  }

  init(): Promise<boolean> {
    if (this._roles) {
      return Promise.resolve(true);
    }
    return new Promise<boolean>((resolve, reject) => {
      this.userServiceProxy.getRoles(this._impersonateTenantId || undefined).toPromise().then((result: PagedResultDtoOfRoleDto) => {
        this._roles = [...result.items];
        resolve(true);
      }, (err) => {
        reject(err);
      });
    });
  }
}
