import { Injectable } from '@angular/core';
import {
  ApplicationInfoDto,
  GetCurrentLoginInformationsOutput,
  SessionServiceProxy,
  TenantLoginInfoDto,
  UserLoginInfoDto,
  InstitutionLoginInfoDto,
} from '@shared/service-proxies/service-proxies';
import { AppAuthService } from '@shared/auth/app-auth.service';

@Injectable()
export class AppSessionService {
  private _user: UserLoginInfoDto;
  private _tenant: TenantLoginInfoDto;
  private _institution: InstitutionLoginInfoDto;
  private _application: ApplicationInfoDto;

  constructor(
    private _sessionService: SessionServiceProxy,
    private _appAuthService: AppAuthService
  ) {}

  get application(): ApplicationInfoDto {
    return this._application;
  }

  get user(): UserLoginInfoDto {
    return this._user;
  }

  get userId(): number {
    return this.user ? this.user.id : null;
  }

  get tenant(): TenantLoginInfoDto {
    return this._tenant;
  }

  get tenantId(): number {
    return this.tenant ? this.tenant.id : null;
  }

  get institution(): InstitutionLoginInfoDto {
    return this._institution;
  }

  init(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this._sessionService
        .getCurrentLoginInformations(
          this._appAuthService.ImpersonateTenantId || undefined
        )
        .toPromise()
        .then(
          (result: GetCurrentLoginInformationsOutput) => {
            this._application = result.application;
            this._user = result.user;
            this._tenant = result.tenant;
            this._institution = result.institution;
            resolve(true);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
