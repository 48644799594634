import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { CommonState } from 'store/reducers/common.reducer';
import { Observable } from 'rxjs';
import { domainNameSelector } from 'store/selectors/common.selector';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.styles.less']
})
export class FooterComponent {

    public domainName$: Observable<string> = this.commonStore$.pipe(select(domainNameSelector));

    constructor(
        private commonStore$: Store<CommonState>
    ) {}

    public getProtocol() {
        return window.location.protocol;
    }
}
