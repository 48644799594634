import { Inject, Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { BehaviorSubject, of as _observableOf, Subject } from 'rxjs';

import { AuthCookieService } from '@app/services/auth-cookie.service';
import { AuthPermissionsService } from '@app/services/auth-permissions.service';

import { userRolesEnum } from '@shared/AppEnums';
import { HttpClient } from '@node_modules/@angular/common/http';
import { url } from 'inspector';
import { API_BASE_URL } from '@shared/service-proxies/service-proxies';
import { mergeMap as _observableMergeMap } from '@node_modules/rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class AppAuthService {
  public impersonateVar = 'ImpersonateTenantId';
  public ImpersonateTenantId = null;
  public impersonateReloadRequired: Subject<null>;

  public userResItem = new Subject<any>();

  constructor(
    private router: Router,
    private _authPermissionChecker: AuthPermissionsService,
    private _authCookieService: AuthCookieService,
    private http: HttpClient,
    @Inject(API_BASE_URL) private baseUrl: string
  ) {
    this.impersonateReloadRequired = new Subject();

    this.ImpersonateTenantId = Number(localStorage[this.impersonateVar]);

    this.impersonateReloadRequired.next();
  }

  logout(reload?: boolean) {
    this._authCookieService.deleteAuthCookie();

    if (reload !== false) {
      location.href = AppConsts.appBaseUrl;
    }
  }

  getAuthTokenClaims() {
    const tokenClaims = JSON.parse(
      atob(this._authCookieService.getAuthCookie().split('.')[1])
    );
    return tokenClaims;
  }

  getUserRole(): any {
    const { role } = this.getAuthTokenClaims();
    return role;
  }

  checkIsUserSuperAdmin(): boolean {
    if (this.getUserRole().isArray) {
      return this.getUserRole()[0] === 'Super Admin';
    } else {
      return this.getUserRole() === 'Super Admin';
    }
  }

  checkIsEmailExist(email: string, loginType: string) {
    const urlForEmail = this.baseUrl + '/api/services/app/User/CheckIsUserEmailExist?UserEmailAddress=' + email + '&LoginType=' + loginType;
    return this.http.get(urlForEmail);
  }

  checkIsUserAccountAdmin(): boolean {
    return this.getUserRole() === 'Account Admin';
  }

  getRole(): number {
    const isUserSuperAdmin = this.checkIsUserSuperAdmin();
    const isUserAccountAdmin = this.checkIsUserAccountAdmin();

    if (isUserSuperAdmin && !localStorage.getItem(this.impersonateVar)) {
      return userRolesEnum['System Admin'];
    } else if (
      isUserAccountAdmin ||
      (isUserSuperAdmin && localStorage.getItem(this.impersonateVar))
    ) {
      return userRolesEnum['Account Admin'];
    } else {
      return userRolesEnum['Student'];
    }
  }

  commonRequest = (requestType, url_, options_) =>
      this.http.request(requestType, url_, options_).pipe(
          _observableMergeMap((response: any) => {
            return _observableOf(response.body.result);
          })
      )

  uploadCsv(fileList: any, tenantId: number) {
    const file: File = fileList[0];

    const formData: FormData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data'});

    const urlApiCall = this.baseUrl + '/api/User/BulkCreate?tenantId=' + tenantId;
    return this.http.post(urlApiCall, formData, {headers});
  }

  setImpersonateData(
    data: SwitchAccountDto,
    params: SetImpersonateParams = {}
  ) {
    localStorage.removeItem(this.impersonateVar);
    const tenantId = String(data.institutionId);

    this.ImpersonateTenantId = tenantId;
    localStorage.setItem(this.impersonateVar, tenantId);

    if (!params.preventReload) {
       this.router.navigate(['/app/programs'])
       .then(() => {
        window.location.reload();
      });
    }
  }

  customDeleteUser(id: number) {
    const urlApiCall = this.baseUrl + `/api/services/app/User/Delete?UserId=${id}`;
    return this.http.delete(urlApiCall);
  }

  refreshTokenAuth() {
    const urlApiCall = this.baseUrl + '/api/TokenAuth/Refresh';
    const body = {
      accessToken: localStorage.getItem('access'),
      refreshToken: localStorage.getItem('refresh')
    };
    return this.http.post(urlApiCall, body);
  }
}

interface SetImpersonateParams {
  preventReload?: boolean;
}

export interface SwitchAccountDto {
  institutionId: number;
}
