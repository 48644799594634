import { PipeTransform, Pipe } from '@angular/core';
import { addBase64Meta, isBase64Meta, removeBase64Meta } from '@shared/utils/image.util';

@Pipe({ name: 'LogoType' })
export class LogoTypePipe implements PipeTransform {

    transform(logo: string): string {
        if (!logo) { return logo; }

        const logoIsUrl = logo.includes('http');
        const logoIsBase64 = isBase64Meta(logo);

        if (!logoIsUrl && !logoIsBase64) {
            return addBase64Meta(logo);
        }

        if (logoIsUrl && logoIsBase64) {
            return removeBase64Meta(logo);
        }

        return logo;
    }
}
