import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ticksToTimeString'
})
export class TicksToTimeStringPipe implements PipeTransform {

  transform(value: string | number): string {
    if (isNaN(+value)) {
      return;
    }
    const seconds = Math.round(+value / 10_000_000);
    return this.getTimeFromSeconds(seconds);
  }

  private getTimeFromSeconds(seconds: number): string {
    let rest = seconds;
    let result = '';
    if (rest >= 3600) {
      result += this.doubleSymbols(Math.floor(rest / 3600)) + ':';
      rest = rest % 3600;
    } else {
      result += '00:';
    }
    if (rest >= 60) {
      result += this.doubleSymbols(Math.floor(rest / 60)) + ':';
      rest = rest % 60;
    } else {
      result += '00:';
    }
    result += this.doubleSymbols(rest);
    return result;
  }

  private doubleSymbols(num: number): string {
    return ('0' + num).slice(-2);
  }

}
