import { AuthCookieService } from '@app/services/auth-cookie.service';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { AuthenticateModel, AuthenticateResultModel, API_BASE_URL } from '@shared/service-proxies/service-proxies';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { throwError as _observableThrow } from 'rxjs';
import { alert } from '../../alert';


@Injectable({
    providedIn: 'root',
})
export class LoginService {

    static readonly twoFactorRememberClientTokenName = 'TwoFactorRememberClientToken';

    authenticateModel: AuthenticateModel;
    rememberMe: boolean;

    constructor(
        private _router: Router,
        private http: HttpClient,
        private loginService: LoginService,
        private _authCookieService: AuthCookieService,
        @Inject(API_BASE_URL) private baseUrl: string
    ) {
        this.clear();
    }

    verificationEmail(email: string) {
        const url = this.baseUrl + '/api/services/app/User/GetUserTenantByEmail?Email=' + email;

        return this.http.get<any>(url);
    }

    ValidateLoginParam(email: string) {
        const url = this.baseUrl + '/api/services/app/User/ValidateLoginParam?Email=' + email;
        return this.http.get<any>(url);
    }

    public login(accessToken: string, encryptedAccessToken: string, expireInSeconds: number, domain: string, rememberMe?: boolean): void {

        const tokenExpireDate = rememberMe ? (new Date(new Date().getTime() + 1000 * expireInSeconds)) : undefined;

        this._authCookieService.setAuthCookie(accessToken, { expires: tokenExpireDate });

        let initialUrl = UrlHelper.initialUrl;
        if (initialUrl.indexOf('/login') > 0 || initialUrl.includes('resetpassword') || initialUrl.includes('account/activate')) {
            initialUrl = AppConsts.appBaseUrl;
        }
        if (domain) {

        } else {
            location.href = initialUrl;
        }

    }

    public specialTokenUpdate(accessToken: string, encryptedAccessToken: string, expireInSeconds: number, domain: string, rememberMe?: boolean) {
        const tokenExpireDate = rememberMe ? (new Date(new Date().getTime() + 1000 * expireInSeconds)) : undefined;

        this._authCookieService.setAuthCookie(accessToken, { expires: tokenExpireDate });
    }


    authenticate(finallyCallback?: () => void): void {
        const url = this.baseUrl + '/api/TokenAuth/Authenticate';

        this.http.post(url, this.authenticateModel)
            .subscribe(
                (response: any) => {
                    localStorage.setItem('access', response.result.accessToken);
                    localStorage.setItem('refresh', response.result.refreshToken);
                    localStorage.setItem('date-login', String(new Date()));
                    this.processAuthenticateResult((response as any).result);
                },
                (data) => {
                    if (data.error && data.error && data.error.error.code === 1001) {
                        const message = data.error.error.message;
                        window.location = message.substring(message.indexOf(': ') + 2);
                    } else if (data.error && data.error && data.error.error.code === 426) {
                        this.handlePasswordExpiredError(data);
                    } else {
                        this.handleError(data);
                    }

                    finallyCallback();
                }
            );
    }

    private handleError = (res: HttpErrorResponse) => {
        alert.error('Login failed!', res.error.error.message, true);
    }

    // TODO: Handle for expired password for user without
    private handlePasswordExpiredError = (res: HttpErrorResponse) => {
        const {userId, token} = JSON.parse(res.error.error.details);
        this._router.navigate([`/account/resetpassword`], {
            queryParams: {
                userId: userId,
                token: token,
                passwordExpired: true,
            }
        });
    }

    private processAuthenticateResult(authenticateResult: any) {
        if (authenticateResult.accessToken) {
            // Successfully logged in
            this.login(
                authenticateResult.accessToken,
                authenticateResult.encryptedAccessToken,
                authenticateResult.expireInSeconds,
                authenticateResult.domain,
                this.rememberMe);

        } else {
            this._router.navigate(['account/login']);
        }
    }

    private clear(): void {
        this.authenticateModel = new AuthenticateModel();
        this.authenticateModel.rememberClient = false;
        this.rememberMe = false;
    }
}
