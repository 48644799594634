import { Injectable } from '@angular/core';
import { ofType, Actions, Effect } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { MESSAGES_ACTION_TYPES } from 'store/action-types/messages.action-types';
import { ToastrService } from '@shared/toastr/toastr.service';
import { map } from 'rxjs/operators';

@Injectable()
export class MessagesEffects {
  // CREATE MESSAGES EFFECTS
  @Effect({ dispatch: false })
  public showCreateSuccessMessage$ = this.getSuccessMessageEffect(
    MESSAGES_ACTION_TYPES.CRUD_MESSAGES.CREATE.SUCCESS
  );

  @Effect({ dispatch: false })
  public showCreateErrorMessage$ = this.getErrorMessageEffect(
    MESSAGES_ACTION_TYPES.CRUD_MESSAGES.CREATE.ERROR
  );
  // //////////////////////////////////////////////////////////////

  // CREATE MESSAGES EFFECTS
  @Effect({ dispatch: false })
  public showDeleteSuccessMessage$ = this.getSuccessMessageEffect(
    MESSAGES_ACTION_TYPES.CRUD_MESSAGES.DELETE.SUCCESS
  );

  @Effect({ dispatch: false })
  public showDeleteErrorMessage$ = this.getErrorMessageEffect(
    MESSAGES_ACTION_TYPES.CRUD_MESSAGES.DELETE.ERROR
  );
  // //////////////////////////////////////////////////////////////

  // CREATE MESSAGES EFFECTS
  @Effect({ dispatch: false })
  public showUpdateSuccessMessage$ = this.getSuccessMessageEffect(
    MESSAGES_ACTION_TYPES.CRUD_MESSAGES.UPDATE.SUCCESS
  );

  @Effect({ dispatch: false })
  public showUpdateErrorMessage$ = this.getErrorMessageEffect(
    MESSAGES_ACTION_TYPES.CRUD_MESSAGES.UPDATE.ERROR
  );
  // //////////////////////////////////////////////////////////////

  // CREATE MESSAGES EFFECTS
  @Effect({ dispatch: false })
  public showReadSuccessMessage$ = this.getSuccessMessageEffect(
    MESSAGES_ACTION_TYPES.CRUD_MESSAGES.READ.SUCCESS
  );

  @Effect({ dispatch: false })
  public showReadErrorMessage$ = this.getErrorMessageEffect(
    MESSAGES_ACTION_TYPES.CRUD_MESSAGES.READ.ERROR
  );
  // //////////////////////////////////////////////////////////////

  constructor(private actions$: Actions<any>, private toastr: ToastrService) {}

  public getSuccessMessageEffect(actionType: string) {
    return this.actions$.pipe(
      ofType(actionType),
      map(({ payload: { title, description } }) => {
        this.toastr.showSuccess(title, description);
      })
    );
  }

  public getErrorMessageEffect(actionType: string) {
    return this.actions$.pipe(
      ofType(actionType),
      map(({ payload: { title, description } }) => {
        this.toastr.showError(title, description);
      })
    );
  }
}
