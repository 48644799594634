import {Inject, Injectable} from '@node_modules/@angular/core';
import {HttpClient, HttpHeaders} from '@node_modules/@angular/common/http';
import {API_BASE_URL} from '@shared/service-proxies/service-proxies';


@Injectable({
    providedIn: 'root'
})
export class HttpService {

    constructor(private http: HttpClient, @Inject(API_BASE_URL) private baseUrl: string) {
    }

    get(url, params?) {
        return this.http.get(this.baseUrl + url, params);
    }

    post(url, body, option: any = {}) {
        return this.http.post(this.baseUrl + url, body, option);
    }

    put(url, body) {
        return this.http.put(this.baseUrl + url, body);
    }

    delete(url) {
        return this.http.delete(this.baseUrl + url);
    }
}
