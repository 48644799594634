import {Injectable} from '@node_modules/@angular/core';
import {HttpService} from './http.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(private httpService: HttpService) {
    }

    postForgotPassword(params) {
        return this.httpService.post('/api/User/ForgotPassword', params);
    }

    postSendActivationCode(params) {
        return this.httpService.post('/api/services/app/User/SendActivationCode', params);
    }

    downloadUserExampleFile(params) {
        return this.httpService.post('/api/User/DownloadUploadUsersExampleFile', params, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    downloadCertificate(params) {
        return this.httpService.post('/api/services/app/UserProfile/DownloadCertificate', params, {
            observe: 'response',
            responseType: 'arraybuffer'
        });
    }
}
