import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'absoluteLink'
})
export class AbsoluteLinkPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    return value.startsWith('http') || value.startsWith('//') ? value : '//' + value;
  }

}
