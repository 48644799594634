import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AuthCookieService {
    private readonly APP_COOKIE_NAME = 'Abp.AuthToken';
    private readonly AGREE_TERM_COOKIE_NAME = 'agreeTeerms';

    constructor() {
    }

    getAuthCookie() {
        const matches = document.cookie.match(new RegExp(
            '(?:^|; )' + this.APP_COOKIE_NAME.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    setAuthCookie(value: string, options: any = {}) {
        options = {
            path: '/',
            ...options
        };

        if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }

        let updatedCookie = encodeURIComponent(this.APP_COOKIE_NAME) + '=' + encodeURIComponent(value);

        // tslint:disable-next-line: forin
        for (const optionKey in options) {
            updatedCookie += '; ' + optionKey;
            const optionValue = options[optionKey];
            if (optionValue !== true) {
                updatedCookie += '=' + optionValue;
            }
        }
        const cookies = document.cookie.split(';');
        cookies.forEach(item => {
            if (item.includes(this.AGREE_TERM_COOKIE_NAME)) {
                let agreeTeermsCookie = encodeURIComponent(this.AGREE_TERM_COOKIE_NAME) + '=' + encodeURIComponent(true);
                // tslint:disable-next-line:forin
                for (const optionKey in options) {
                    agreeTeermsCookie += '; ' + optionKey;
                    const optionValue = options[optionKey];
                    if (optionValue !== true) {
                        agreeTeermsCookie += '=' + optionValue;
                    }
                }
                document.cookie = agreeTeermsCookie;
            }
        });
        document.cookie = updatedCookie;
    }

    deleteAuthCookie() {
        const agreeTermsCookie = this.getCookieValue(this.AGREE_TERM_COOKIE_NAME);
        this.setAuthCookie('', {
            'max-age': -1
        });
        if (agreeTermsCookie) {
            const agreeCookie = encodeURIComponent(this.AGREE_TERM_COOKIE_NAME) + '=' + encodeURIComponent(agreeTermsCookie);
            document.cookie = agreeCookie;
        }
    }
    getCookieValue(cookieName: string): string {
        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
            const [name, value] = cookie.split('=');
            const trimmedName = name.trim();
            if (trimmedName === cookieName) {
                return decodeURIComponent(value);
            }
        }
        return '';
    }
}
