/* Return full icon name in case of missing the icon class names */
export const handleIconPlacement = (icon: string): string => {
    if (
        icon &&
        typeof icon === 'string' &&
        !icon.includes('fa-')
    ) {
        return `fab fas fa-${icon}`;
    }
    return icon;
};
