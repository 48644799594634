import { createSelector, createFeatureSelector } from '@ngrx/store';
import { CommonState, commonNode } from '../reducers/common.reducer';

// Select commonState from global store
export const selectCommon = createFeatureSelector<CommonState>(commonNode);

// Select isMobile property from common state
export const selectIsMobile = createSelector(
    selectCommon,
    (state: CommonState) => state.isMobile
);

export const domainNameSelector = createSelector(
    selectCommon,
    (state: CommonState) => state.domainName
);
