import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

export interface IInstructorDialogModalData {
  instructorId: number;
}

@Component({
  templateUrl: './instructor-dialog.component.html',
  selector: 'instructor-dialog',
  styleUrls: ['./instructor-dialog.less'],
})
export class InstructorDialogComponent {
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IInstructorDialogModalData,
    private dialogRef: MatDialog,
    private router: Router
  ) {}

  public onViewProfile() {
    this.dialogRef.closeAll();
    this.router.navigate([
      `/app/instructor/${this.data.instructorId}`,
      { prevRoute: this.router.url },
    ]);
  }

  public closeModal() {
    this.dialogRef.closeAll();
  }
}
