import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { API_BASE_URL } from '@shared/service-proxies/service-proxies';
import { ToastrService } from '@shared/toastr/toastr.service';
import { Observable, throwError } from 'rxjs';
import { AuthCookieService } from '@app/services/auth-cookie.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuizService {
  constructor(
    private http: HttpClient,
    private authCookieService: AuthCookieService,
    private toastr: ToastrService,
    @Inject(API_BASE_URL) private baseUrl: string
  ) {}

  importQuiz(classId: string, file: File, quizName): Observable<any> {
    const token = this.authCookieService.getAuthCookie();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': this.baseUrl,
      'Access-Control-Expose-Headers': 'Content-Disposition'
    });

    const url = this.baseUrl + '/api/services/app/Quiz/ImportQuiz';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('classId', classId);
    formData.append('title', quizName);

    return this.http.post(url, formData, { headers }).pipe(catchError(this.errorHandler));
  }

  private errorHandler(err: HttpErrorResponse) {
    return throwError(new HttpErrorResponse({error: err.error.error}));
  }

}
