import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppAuthService } from '@shared/auth/app-auth.service';
import { Injectable } from '@angular/core';
import { AuthCookieService } from '@app/services/auth-cookie.service';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {


    constructor(private _authService: AppAuthService, private _authCookieService: AuthCookieService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const newReq: any = req.clone({
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this._authCookieService.getAuthCookie()}`
            }),
            params: this._authService.ImpersonateTenantId && (req.method === 'GET' || req.method === 'DELETE') ? req.params.set('ImpersonateTenantId', this._authService.ImpersonateTenantId) : req.params,
        });

        if ((req.method === 'POST' || req.method === 'PUT') && this._authService.ImpersonateTenantId) {
            newReq.body.impersonateTenantId = this._authService.ImpersonateTenantId;
        }
        return next.handle(newReq);
    }
}
