import { Component, Input, OnInit } from '@angular/core';
import { AppSessionService } from '@shared/session/app-session.service';
import { UiService } from '@shared/ui/ui.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.less']
})
export class NotFoundComponent implements OnInit {
  @Input() showTopBar = true;

  public imgSrc = 'assets/education-book.svg';

  constructor(
    private uiService: UiService,
    private _sessionService: AppSessionService,
    private location: Location
  ) {}

  get isLoggedIn() {
    return Boolean(this._sessionService.user);
  }

  ngOnInit() {
    this.uiService.togglePageLoader(false);
  }

  goBack() {
    this.location.back();
  }
}
