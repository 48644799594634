import { Component, OnInit } from '@angular/core';
import { CommonState } from './store/reducers/common.reducer';
import { Store, select } from '@ngrx/store';
import { InitMobileCheckAction } from 'store/actions/common.actions';
import { Observable } from 'rxjs';
import { selectIsMobile } from 'store/selectors/common.selector';

@Component({
    selector: 'app-root',
    template: `<router-outlet></router-outlet>`
})
export class RootComponent implements OnInit {
    // Get is Mobile from store
    private isMobile$: Observable<boolean> = this.store$.pipe(select(selectIsMobile));

    constructor(
        private store$: Store<CommonState>,
        ) {
        this.isMobile$.subscribe(isMobile => {
            if (isMobile) {
                document.body.classList.add('mobile');
            }
        });
    }

    ngOnInit() {
        // Dispatching an action for mobile check
        this.store$.dispatch(InitMobileCheckAction());
    }
 }
