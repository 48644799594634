import { PipeTransform, Pipe } from '@angular/core';
import { handleIconPlacement } from '@shared/utils/icon.util';

@Pipe({ name: 'TransformIcon'})
export class TransformIconPipe implements PipeTransform  {

  constructor() {}

  transform(icon: string): string {
    if (icon) {
      return handleIconPlacement(icon);
    }

    return icon;
  }
}
