import { createAction } from '@ngrx/store';
import { COMMON_ACTION_TYPES } from '../action-types/common.action-types';

export const InitMobileCheckAction = createAction (
    COMMON_ACTION_TYPES.INIT_MOBILE_CHECK
);

export const MobileCheckSuccess = createAction(
    COMMON_ACTION_TYPES.MOBILE_CHECK_SUCCESS,
    (isMobile: boolean) => ({ payload: { isMobile } })
);


