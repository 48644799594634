import { Component, Input } from '@angular/core';
import {
  InstructorDialogComponent,
  IInstructorDialogModalData,
} from '@app/app-components/instructor-dialog/instructor-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { InstructorDto } from '@shared/service-proxies/service-proxies';
import { ToastrService } from '@shared/toastr/toastr.service';

interface InstructorDtoExt extends InstructorDto {
  isActive: boolean;
}

@Component({
  selector: 'instructors-thumbs',
  templateUrl: './instructors-thumbs.component.html',
  styleUrls: ['./instructors-thumbs.less'],
})
export class InstructorsThumbsComponent {
  @Input() instructors: InstructorDtoExt[];
  @Input() expanded = false;
  @Input() contrast = false;

  constructor(private dialog: MatDialog, private toastr: ToastrService) {}

  openInstructorDialog(instructor: InstructorDtoExt) {
    event.stopPropagation();

    const data: IInstructorDialogModalData = {
      instructorId: instructor.id,
    };

    this.dialog.open(InstructorDialogComponent, {
      width: '600px',
      data,
      panelClass: 'unpadding',
    });
  }

  trimInstructorName(name: string): string {
    return (
      name &&
      name
        .split('')
        .filter((el, i) => el !== ' ' || (i !== 0 && i !== name.length - 1))
        .join('')
    );
  }
}
