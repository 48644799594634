import { Injectable, Inject } from '@angular/core';
import { HttpResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '@shared/service-proxies/service-proxies';
import { AuthCookieService } from '@app/services/auth-cookie.service';
import { ToastrService } from '@shared/toastr/toastr.service';

@Injectable({
  providedIn: 'root',
})
export class MaterialService {
  constructor(
    private http: HttpClient,
    private authCookieService: AuthCookieService,
    private toastr: ToastrService,
    @Inject(API_BASE_URL) private baseUrl: string
  ) {}

  downloadMaterialById(id: number): void {
    const token = this.authCookieService.getAuthCookie();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': this.baseUrl,
      'Access-Control-Expose-Headers': 'Content-Disposition',
    });

    this.http
      .get(`${this.baseUrl}/material/getmaterialdownload`, {
        headers,
        params: { materialId: id.toString() },
        responseType: 'blob',
        observe: 'response',
      })
      .subscribe(this.saveAsFile, this.handleDownloadError);
  }

  downloadInstructorFileById(id: number): void {
    const token = this.authCookieService.getAuthCookie();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': this.baseUrl,
      'Access-Control-Expose-Headers': 'Content-Disposition',
    });

    this.http
      .get(`${this.baseUrl}/material/getinstructorfiledownload`, {
        headers,
        params: { instructorFileId: id.toString() },
        responseType: 'blob',
        observe: 'response',
      })
      .subscribe(this.saveAsFile, this.handleDownloadError);
  }

  saveAsFile(resp: HttpResponse<Blob>) {
    const link = document.createElement('a');
    link.download = resp.headers.has('Content-Disposition')
      ? resp.headers.get('Content-Disposition').match(/filename=(.*?);/)[1]
      : 'file';
    if (link.download.startsWith('"')) {
      link.download = link.download.slice(1);
    }
    if (link.download.endsWith('"')) {
      link.download = link.download.slice(0, -1);
    }
    link.href = URL.createObjectURL(resp.body);

    link.click();
    URL.revokeObjectURL(link.href);
  }

  handleDownloadError(response: any) {
    this.toastr.showError('An error occurred', response);
  }
}
