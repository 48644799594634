const swal = require('sweetalert');

const enum swalTypes {
    error = 'error',
    success = 'success',
    warning = 'warning',
    info = 'info'
}

type SwalOptions = {
    title: string,
    text: string,
    icon: swalTypes,
    button?: string | boolean
};

function callSwal(options: SwalOptions) {
    swal(options);
}

function callSwalWithRedirect(options: SwalOptions, redirectUrl: string) {
    swal(options).then(function() {
        window.location.href = redirectUrl;
    });
}

export const alert = {
    error: (title, text, button = false) => callSwal({title, text, icon: swalTypes.error, button}),
    errorWithRedirect: (title, text, redirectUrl, button = false) => callSwalWithRedirect({title, text, icon: swalTypes.error, button}, redirectUrl),
    success: (title, text, button = 'Ok') => callSwal({title, text, icon: swalTypes.success, button}),
    warning: (title, text, button = 'Ok') => callSwal({title, text, icon: swalTypes.warning, button}),
    info: (title, text, button = 'Ok') => callSwal({title, text, icon: swalTypes.info, button}),
};
