export enum CRUDOperationResults {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

type CRUDOperationActions = {
  SUCCESS: string;
  ERROR: string;
};

export const generateMessagesCRUDActionTypes = (
  crudOperationName: string
): CRUDOperationActions => ({
  [CRUDOperationResults.SUCCESS]: `${crudOperationName} SUCCESS`,
  [CRUDOperationResults.ERROR]: `${crudOperationName} ERROR`,
});
