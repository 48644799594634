import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secToMinSec'
})
export class SecToMinSecPipe implements PipeTransform {

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    const seconds: number = value - minutes * 60;
    const minutesFormatted: string = (minutes < 10 ? '0' : '') + minutes;
    const secondsFormatted: string = (seconds < 10 ? '0' : '') + seconds;
    return minutesFormatted + ':' + secondsFormatted;
  }

}
