import {
  Component,
  Injector,
  ViewEncapsulation,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { MatDialog, MatMenuTrigger } from '@angular/material';
import { Router, NavigationStart } from '@angular/router';
import { Subject } from 'rxjs';

import { AuthPermissionsService } from '@app/services/auth-permissions.service';
import {
  PagedResultDtoOfGetInstitutionsNameResponseDto,
  InstitutionServiceProxy,
  UserLoginInfoDto,
} from '@shared/service-proxies/service-proxies';
import {
  AppAuthService,
  SwitchAccountDto,
} from '@shared/auth/app-auth.service';
import { AppSessionService } from '@shared/session/app-session.service';
import { AppComponentBase } from '@shared/app-component-base';
import { AppConsts } from '@shared/AppConsts';

import {
  IProgramOverviewDto,
  ProgramOverviewDto,
  ProgramsService,
} from '@app/pages/programs/programs.service';
import { SwitchAccountComponent } from '@shared/switch-account/switch-account.component';
import { WhiteLabelService } from '@shared/white-label/white-label.service';
import { userRolesEnum } from '@shared/AppEnums';
import { TopbarService } from '@app/services/topbar.service';
import { TenantService } from '../../../new_services/tenant.service';

@Component({
  templateUrl: './topbar.component.html',
  selector: 'top-bar',
  styleUrls: ['./topbar.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class TopBarComponent extends AppComponentBase
  implements OnInit, AfterViewInit {
  @ViewChild('menuTrigger', { static: true }) menuTrigger: MatMenuTrigger;
  @ViewChild('programIconsContainer', { static: true })
  programIconsContainer: ElementRef;
  @ViewChild('topbarElement', { static: true }) topbarElement: ElementRef;

  user: UserLoginInfoDto | any = {};
  isAdmin: boolean;
  institutionName: string;
  tenantName: string;
  programs: IProgramOverviewDto[] = [];
  selectedProgramId: number;

  readonly nameDisplayLength = 20;
  readonly tooltipStyles = {
    width: '200px',
    'background-color': '#333',
    color: '#f1f1f1',
    'font-family': 'tecna_lightregular',
    'font-size': '11px',
    padding: '4px 4px',
  };

  constructor(
    private sessionService: AppSessionService,
    private permissionChecker: AuthPermissionsService,
    private authService: AppAuthService,
    private institutionServiceProxy: InstitutionServiceProxy,
    private programsService: ProgramsService,
    private whiteLabelService: WhiteLabelService,
    private dialog: MatDialog,
    private tenantService: TenantService,
    private router: Router,
    public topbarService: TopbarService,
    injector: Injector
  ) {
    super(injector);
    this.programsService.programsSubject.subscribe(
      (result) => {
        this.programs = result;
      }
    );
  }

  get isRealSystemAdmin(): boolean {
    return (
      this.authService.getRole() === userRolesEnum['System Admin'] ||
      this.authService.ImpersonateTenantId
    );
  }

  getLogo() {
    return this.whiteLabelService.mainLogo;
  }

  ngOnInit() {
    this.sessionService.init().then(() => {
      const { user, tenant, institution } = this.sessionService;

      this.user = user;
      this.isAdmin = this.permissionChecker.isGranted('Pages.Users');
      this.tenantName = tenant
        ? tenant.name
        : AppConsts.userManagement.defaultHostTenantName;
      this.institutionName = institution
        ? institution.name
        : AppConsts.userManagement.defaultHostInstitutionName;
    });
    this.getPrograms();
    this.selectProgram();
    this.getRoutes();
  }

  ngAfterViewInit() {
    this.topbarService.setElementRef(this.topbarElement);
  }

  switchAccount() {
    const institutionList = new Subject();

    this.menuTrigger.closeMenu();

    const objRequest = {
      PageNumber: 1,
      MaxResultCount: 1000
    };
    this.tenantService.getGetAll(objRequest).subscribe((res: any) => {

      this.dialog.open(SwitchAccountComponent, {
        width: '600px',
        height: '328px',
        disableClose: true,
        position: {top: '150px'},
        data: {institutionList: res.result.items}
      })
          .afterClosed()
          .subscribe((response: SwitchAccountDto) =>
              response && this.authService.setImpersonateData(response)
          );
    });
  }

  logout() {
    this.authService.logout();
  }

  trimProgramName(name: string): string {
    return name.length > this.nameDisplayLength
      ? name.slice(0, 20) + '...'
      : name;
  }

  setTooltipStyles(name: string) {
    return name.length <= this.nameDisplayLength
      ? { display: 'none' }
      : this.tooltipStyles;
  }

  toggleExpandedState() {
    this.topbarService.toggleIsExpandedState();
  }

  private getRoutes() {
    this.router.events.subscribe(
      (event) =>
        event instanceof NavigationStart && this.selectProgram(event.url)
    );
  }

  private selectProgram(url?: string) {
    this.selectedProgramId = Number((url || this.router.url).split('/')[3]);
  }

  private getPrograms() {
    this.programs = [];
    this.programsService.retrievePrograms(() => {
      this.programs = this.programsService.programs;
    });
  }
}
