export const isBase64Meta = (image: string, type?: string): boolean => {
    if (!image || !image.length) { return null; }

    return image.includes(type ? `data:image/${type}` : 'data:image');
};

export const addBase64Meta = (image: string, type?: string): string => {
    if (!image || !image.length) { return null; }

    const imageType = type || 'png';
    return `data:image/${imageType};base64,${image}`;
};

export const removeBase64Meta = (image: string): string => {
    if (!image || !image.length) { return null; }

    const base64MetaRegEx = /(data:image\/).*(base64,)/g;
    return image.includes('data:image') ?
        image.replace(base64MetaRegEx, '') :
        image;
};
