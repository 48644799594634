import { Injectable } from '@angular/core';
import { AuthPermissionsService } from '@app/services/auth-permissions.service';
import { AppSessionService } from '../session/app-session.service';

import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';
import { userRolesEnum } from '@shared/AppEnums';
import { AppAuthService } from './app-auth.service';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild {
  constructor(
    private _permissionChecker: AuthPermissionsService,
    private _router: Router,
    private _sessionService: AppSessionService,
    private _appAuthService: AppAuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this._sessionService.user) {
      this._router.navigate(['/account/login']);
      return false;
    }

    const permissionToCheck = route.data['permission'];
    const roleToCheck = route.data['role'];

    if (!route.data || (!roleToCheck && !permissionToCheck)) {
      return true;
    }

    const doesPermissionPass =
      !permissionToCheck ||
      this._permissionChecker.isGranted(route.data['permission']);
    let doesRolePass: boolean;
    const isImpersonating = !!this._appAuthService.ImpersonateTenantId;
    const isSystemAdmin = this._appAuthService.checkIsUserSuperAdmin();
    const isAccountAdmin = this._appAuthService.checkIsUserAccountAdmin();

    if (!roleToCheck) {
      doesRolePass = true;
    } else if (roleToCheck === userRolesEnum['System Admin']) {
      doesRolePass = isSystemAdmin && !isImpersonating;
    } else if (roleToCheck === userRolesEnum['Account Admin']) {
      doesRolePass = isAccountAdmin || (isSystemAdmin && isImpersonating);
    }

    if (doesPermissionPass && doesRolePass) {
      return true;
    }

    this._router.navigate([this.selectBestRoute()]);
    return false;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }

  selectBestRoute(): string {
    if (!this._sessionService.user) {
      return '/account/login';
    }

    if (this._permissionChecker.isGranted('Pages.Users')) {
      return '/admin';
    }

    return '/app/programs';
  }
}
