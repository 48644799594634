import * as COMMON_ACTIONS from '../actions/common.actions';
import { Action, on, createReducer } from '@ngrx/store';

export const commonNode = 'common';

export interface CommonState {
  isMobile: boolean;
  showPagePreloader: boolean;
  domainName: string;
}

const initialState: CommonState = {
  isMobile: false,
  showPagePreloader: false,
  domainName: window['env']['DOMAIN_NAME']
};

const reducer = createReducer(
  initialState,
  on(COMMON_ACTIONS.MobileCheckSuccess, (state, action) => ({
    ...state,
    isMobile: action.payload.isMobile,
  }))
);

export function commonReducer(state: CommonState | undefined, action: Action) {
  return reducer(state, action);
}
