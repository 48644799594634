import {
    Component,
    HostListener,
    ViewChild,
    Output,
    Input,
    EventEmitter
} from '@angular/core';

@Component({
    selector: 'app-color-selector',
    templateUrl: './color-selector.component.html',
    styleUrls: ['./color-selector.less'],
})
export class ColorSelectorComponent {
    @ViewChild('section', { static: true }) section: any;

    @Input() colors = [
        '#e6194b', '#3cb44b', '#ffe119',
        '#4363d8', '#f58231', '#911eb4',
        '#46f0f0', '#f032e6', '#bcf60c',
        '#fabebe'
    ];

    @Input() title: string;
    @Input() selectedColor: string;
    @Output() colorSelected = new EventEmitter();

    isOpen: boolean;
    isFullyOpened: boolean;

    @HostListener('document:click', ['$event'])
    onOffsetClick(e: any) {
        const clickedOnColor = this.clickedOn(e, 'color-selector-item');
        const clickedOnWindow = this.clickedOn(e, 'color-selector-window');

        if (!this.isFullyOpened || clickedOnWindow) { return; }

        if (clickedOnColor) {
            setTimeout(() => this.closePopup(), 100);
            return;
        }

        this.closePopup();
    }

    openSelector() {
        if (this.isFullyOpened) {
            this.closePopup();
            return;
        }

        this.openPopup();
    }

    selectColor(color: string) {
        this.selectedColor = color;
        this.colorSelected.emit(color);
    }

    private openPopup() {
        this.isOpen = true;
        setTimeout(() => this.isFullyOpened = true, 250);
    }

    private closePopup() {
        this.isOpen = false;
        this.isFullyOpened = false;
    }

    private clickedOn(e: any, className: string) {
        return e.target.className.includes(className);
    }
}
