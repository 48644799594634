import { IsTenantAvailableOutputState } from '@shared/service-proxies/service-proxies';

export class AppTenantAvailabilityState {
  static Available: number = IsTenantAvailableOutputState._1;
  static InActive: number = IsTenantAvailableOutputState._2;
  static NotFound: number = IsTenantAvailableOutputState._3;
}

export enum progressStatusEnum {
  'New' = 1,
  'In Progress' = 2,
  'Completed' = 3
}

export enum levelOfDifficultyEnum {
  'Beginner' = 1,
  'Intermediate',
  'Advanced'
}

export enum defaultStatusEnum {
  'Active' = 1,
  'Deactivated' = 2
}

/* AdminPanel > Programs */
export enum programAccessEnum {
  'Private' = 1,
  'Specific Groups' = 2,
  'Global' = 3
}

export enum publishStatusEnum {
  'Published' = 1,
  'Unpublished' = 2
}

export enum extendedPublishStatusEnum {
  'Draft' = 1,
  'Published' = 2,
  'Unpublished' = 3
}

export enum classAccessEnum {
  'Private' = 1,
  'Restricted' = 2,
  'Global' = 3
}

export enum userRolesEnum {
  'Student' = 1,
  'Account Admin' = 2,
  'System Admin' = 3
}

export enum billingFreqEnum {
  'Monthly' = 1,
  'Annually' = 2
}

export enum questionTypes {
  'Multiple' = 1,
  'Bool' = 2,
  'Text' = 3,
  'MultipleRequired' = 4
}

export enum classVideoTypes {
  'Instructor View' = 1,
  'Screen View' = 2,
  'Other View' = 3,
  'Class' = 4
}

export enum generateTestRestsTypeEnum {
  'Excel' = 0,
  'CSV' = 1
}
