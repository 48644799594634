import {
  ActionReducerMap,
  MetaReducer,
  combineReducers,
  Action,
} from '@ngrx/store';

import {
  CommonState,
  commonReducer,
  commonNode,
} from './reducers/common.reducer';
import { InjectionToken } from '@angular/core';

export interface State {
  [commonNode]: CommonState;
}

export const reducers = new InjectionToken<ActionReducerMap<State, Action>>(
  'Root reducers token',
  {
    factory: () => ({
      [commonNode]: commonReducer,
    }),
  }
);

export const metaReducers: MetaReducer<State>[] = [];
