import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    templateUrl: './warning-dialog.component.html',
    styleUrls: ['../dialog-default.styles.less']
})
export class WarningDialogComponent implements OnInit {
    primaryTitle: string;
    primaryButtonTitle: string;
    secondaryButtonTitle: string;
    primaryText: string;
    secondaryText: string;

    constructor(
        private dialogRef: MatDialogRef<WarningDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.primaryButtonTitle = 'Ok';
    }

    ngOnInit() {
        if (this.data) {
            this.applyInnerData();
        }
    }

    onClose(data?: any) {
        this.dialogRef.close(data);
    }

    onPrimaryClick() {
        if (this.data && this.data.onPrimaryClick) {
            this.data.onPrimaryClick();
        }
        this.onClose();
    }

    onSecondaryClick() {
        if (this.data && this.data.onSecondaryClick) {
            this.data.onSecondaryClick();
        }
        this.onClose();
    }

    private applyInnerData() {
        const {
            primaryTitle,
            primaryButtonTitle,
            secondaryButtonTitle,
            primaryText,
            secondaryText
        } = this.data;

        this.primaryTitle = primaryTitle;
        this.primaryButtonTitle = primaryButtonTitle || this.primaryButtonTitle;
        this.secondaryButtonTitle = secondaryButtonTitle;
        this.primaryText = primaryText;
        this.secondaryText = secondaryText;
    }
}
