import {Component, Injector, ViewEncapsulation} from '@angular/core';
import {AppComponentBase} from '@shared/app-component-base';
import {accountModuleAnimation} from '@shared/animations/routerTransition';
import {ActivatedRoute, Router} from '@angular/router';
import {OnDestroy, OnInit} from '@node_modules/@angular/core';
import {UiService} from '@shared/ui/ui.service';
import {url} from 'inspector';
import * as programsActions from '@app/store/actions/programs.actions';
import {UserService} from '../../new_services/user.service';
import {HttpResponse} from '@node_modules/@angular/common/http';
import {Subscription} from '@node_modules/rxjs';
import * as userManagementActions from '@admin-panel/store/actions/user-management.actions';
import {DomSanitizer} from '@angular/platform-browser';
import {SafeUrl} from '@node_modules/@angular/platform-browser';

@Component({
    templateUrl: './certificate.component.html',
    styleUrls: ['./certificate.component.less'],
    animations: [accountModuleAnimation()],
    encapsulation: ViewEncapsulation.None,
})
export class CertificateComponent implements OnInit, OnDestroy {

    subscription: Subscription = new Subscription();
    certificateId: string;


    content: SafeUrl | ArrayBuffer;


    constructor(injector: Injector, private router: Router, private uiService: UiService, private userService: UserService, public domSanitizer: DomSanitizer) {
        this.selectProgram();
    }

    ngOnInit() {
        this.preview();
        console.log(this.certificateId);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    preview() {
        console.log(this.certificateId);
        const downloadDocumentSubscription = this.userService.downloadCertificate({Id: this.certificateId})
            .subscribe(
                // @ts-ignore
                (response: HttpResponse<ArrayBuffer>) => {

                    const file = new Blob([response.body], {type: 'application/pdf'});

                    const fileURL = URL.createObjectURL(file);

                    this.content = this.domSanitizer.bypassSecurityTrustUrl(fileURL);
                    console.log(this.content);
                    this.uiService.togglePageLoader(false);
                },
                (err) => {

                }
            );
    }

    private selectProgram(uri?: string): void {
        this.certificateId = String((uri || this.router.url).split('/')[2]);
    }

}
