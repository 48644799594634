import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'viewNameToIconCode'
})
export class ViewNameToIconCodePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    switch (value) {
      case 'Instructor View':
        return 'account_circle';
      case 'Other View':
        return 'group';
      case 'Screen View':
        return 'computer';
      default:
        return 'lent';
    }
  }

}
