import { Injectable } from '@angular/core';

type BasePermissions = Record<string, string>;

interface AuthPermissions {
  all: BasePermissions;
  granted: BasePermissions;
}


@Injectable({
  providedIn: 'root'
})
export class AuthPermissionsService {
  static authPermissions: AuthPermissions = { all: {}, granted: {} };

  constructor() { }

  initializePermissions = (authPermissions: any): void => {
    AuthPermissionsService.authPermissions.all = authPermissions.allPermissions;
    AuthPermissionsService.authPermissions.granted = authPermissions.grantedPermissions;
  }


  hasPermission(permissionName: string) {
    return this.isGranted(permissionName);
  }


  isGranted(permissionName: string): boolean {
    return !!(AuthPermissionsService.authPermissions.all[permissionName] && AuthPermissionsService.authPermissions.granted[permissionName]);
  }

  isAnyGranted(...permissions: string[]) {
    if (!permissions || permissions.length <= 0) {
      return true;
    }

    if (permissions.find((permission) => this.isGranted(permission))) {
      return true;
    }

    return false;
  }

  areAllGranted(...permissions: string[]) {
    if (!permissions || permissions.length <= 0) {
      return true;
    }

    if (permissions.some((permission) => !this.isGranted(permission))) {
      return false;
    }

    return true;
  }



}
