import { generateMessagesCRUDActionTypes } from '../utils/generate-action-types';

export const MESSAGES_ACTION_TYPES = {
  CRUD_MESSAGES: {
    CREATE: generateMessagesCRUDActionTypes('[MESSAGES] CREATE MESSAGE'),
    READ: generateMessagesCRUDActionTypes('[MESSAGES] READ MESSAGE'),
    UPDATE: generateMessagesCRUDActionTypes('[MESSAGES] UPDATE MESSAGE'),
    DELETE: generateMessagesCRUDActionTypes('[MESSAGES] DELETE MESSAGE'),
  },
};
