import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Type, CompilerOptions, NgModuleRef } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { AppConsts } from '@shared/AppConsts';

import { AuthCookieService } from '@app/services/auth-cookie.service';
import { AuthPermissionsService } from '@app/services/auth-permissions.service';

import { environment } from '../environment';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';

interface ICallbackArgs {
  whiteLabel: any;
}

export class AppPreBootstrap {
  static authCookieService = new AuthCookieService();
  static authPermissionsService = new AuthPermissionsService();

  static run(
    appRootUrl: string,
    callback: (args: ICallbackArgs) => void,
    errorCallback: any
  ): void {
    AppPreBootstrap.getApplicationConfig(appRootUrl, async () => {
      // Start Azure insights telemetry data collection
      AppPreBootstrap.startTelemetryCollection();
      // //////////////////////////////////////////////

      const whiteLabel: any = await AppPreBootstrap.getWhiteLabelConfiguration();
      if (!whiteLabel.success) {
        const error = new Error(whiteLabel.error.message);
        errorCallback(error);
        return;
      }
      if (whiteLabel.result.redirect) {
        window.location = whiteLabel.result.redirect;
        return;
      }

      let isError = false;
      await AppPreBootstrap.getUserConfiguration().catch((e) => {
        isError = true;
        errorCallback(e);
      });

      if (isError) {
        return;
      }

      callback({ whiteLabel: whiteLabel.result });
    });
  }

  static startTelemetryCollection() {
    const disableTelemetry = AppConsts.azureInsightsApiKey ? false : true;

    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: AppConsts.azureInsightsApiKey,
        enableAutoRouteTracking: true,
        disableFetchTracking: false,
        disableTelemetry,
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }

  static bootstrap<TM>(
    moduleType: Type<TM>,
    compilerOptions?: CompilerOptions | CompilerOptions[]
  ): Promise<NgModuleRef<TM>> {
    return platformBrowserDynamic().bootstrapModule(moduleType, compilerOptions);
  }

  static getUserConfiguration(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const response = await fetch(
          `${AppConsts.remoteServiceBaseUrl}/AbpUserConfiguration/GetAll`,
          {
            method: 'GET',
            headers: {
              Authorization: 'Bearer ' + AppPreBootstrap.authCookieService.getAuthCookie(),
            },
          }
        );
        const userConfigurations = await response.json();

        AppPreBootstrap.authPermissionsService.initializePermissions(
          userConfigurations.result.auth
        );

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  private static async getApplicationConfig(appRootUrl: string, callback: () => void) {
    AppConsts.appBaseUrl = location.origin;
    AppConsts.remoteServiceBaseUrl = environment.apiUrl;
    AppConsts.localeMappings = '';
    AppConsts.isDevelopment = environment.env === 'Development';
    AppConsts.azureInsightsApiKey = environment.azureInsightsApiKey;

    callback();
  }

  private static async getWhiteLabelConfiguration() {
    const response = await fetch(`${AppConsts.remoteServiceBaseUrl}/api/TokenAuth/GetWhiteLabel`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + AppPreBootstrap.authCookieService.getAuthCookie(),
      },
    });
    return await response.json();
  }
}
