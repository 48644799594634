import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

interface FilterConditions {
    search?: string | string[];
    filterFields?: any[];
}

@Component({
    selector: 'no-results-btn',
    templateUrl: './no-results-btn.component.html',
    styleUrls: ['./no-results-btn.component.less']
})
export class NoResultsBtnComponent implements OnChanges {
    @Input('buttonTitle') buttonTitle = 'Clear filters';
    @Input('headTitle') headTitle = 'No matching results';
    @Input('buttonBgColor') buttonBgColor: string;
    @Input('filterConditions') filterConditions: FilterConditions;

    @Output() onPrimaryClick = new EventEmitter<any>();

    onButtonClick() {
        this.onPrimaryClick.emit();
    }

    ngOnChanges() {
        const { search, filterFields } = this.filterConditions;
        const filtersApplied = this.checkForAppliedFilters(filterFields);

        if (search && search.length) {
            this.buttonTitle = 'Clear search';

            if (filtersApplied) {
                this.buttonTitle += ' and filters';
            }
        }
    }

    private checkForAppliedFilters(filters: any[]): boolean {
        if (filters && filters.length) {
            return Boolean(filters.filter(item => item.selected).length);
        }
    }
}
