import { Subject, throwError } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { API_BASE_URL, IInstitutionShort } from '@shared/service-proxies/service-proxies';
import { ToastrService } from '@shared/toastr/toastr.service';
import ProgramTableItem from './table-item.model';
import { handleIconPlacement } from '@shared/utils/icon.util';
import { addBase64Meta, removeBase64Meta } from '@shared/utils/image.util';
import { dataURItoBlob } from '@shared/service-proxies/service-proxies.utils';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import { AppAuthService } from '@shared/auth/app-auth.service';

const makeHttpParams = (params: {}) => {
  const obj = {};
  Object.keys(params).forEach((key) => {
    if (params[key] || params[key] === false) {
      obj[key] = params[key];
    }
  });
  return obj;
};

@Injectable()
export class ProgramsService {
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private authService: AppAuthService,
    @Inject(API_BASE_URL) private baseUrl: string
  ) {}

  public getNormalizedPictureURI(pictureURI: string) {
    const isPictureFromServerRegExp = new RegExp(/(http(s?)):\/\//i);
    if (isPictureFromServerRegExp.test(pictureURI)) {
      return '';
    }
    return pictureURI;
  }

  public getProgramsCreateModel(programData: ProgramDTO, impersonateTenantId: any = null) {
    const programsCreateModel = new FormData();

    if (programData.id) {
      programsCreateModel.append('Id', String(programData.id));
    }

    if (impersonateTenantId) {
        programsCreateModel.append('ImpersonateTenantId', impersonateTenantId);
    }
    programsCreateModel.append('Name', programData.name);

    programsCreateModel.append('Description', programData.description);

    programsCreateModel.append('Access', String(programData.access));

    programsCreateModel.append('ColorTheme', programData.themeColor);

    programsCreateModel.append('SendNotifications', String(programData.sendNotifications));

    programsCreateModel.append('Icon', programData.icon);
    if (programData && programData.groups) {
      programData.groups.forEach(tenant => {
        programsCreateModel.append('GroupIds', tenant);
      });
    }

    if (programData.institutions) {
      programData.institutions.map((id) => {
            if (id !== undefined) {
              return programsCreateModel.append('TenantId', String(id));
            }
          }
      );
    }

    const picture = dataURItoBlob(this.getNormalizedPictureURI(programData.picture));

    if (programData.picture) {
      programsCreateModel.append('BackgroundImage', picture);
    } else {
      programsCreateModel.append('RemoveBackgroundImage', 'true');
    }

    return programsCreateModel;
  }

  /* GET SINGLE PROGRAM */
  public getSingleProgram = (id: number): Subject<ProgramDTO> => {
    const subject = new Subject<ProgramDTO>();
    const programId = String(id);

    this.http
      .get(this.baseUrl + '/api/services/app/Program/GetProgramById', {
        params: { programId },
      })
      .subscribe(
        (response: GetSingleProgramResp) => subject.next(this.mapSingleProgram(response.result)),
        this.handleError
      );
    return subject;
  }

  /* GET ALL PROGRAMS */
  public getAllPrograms = (params?: any): Subject<GetAllProgramsOutput> => {
    const subject = new Subject<GetAllProgramsOutput>();

    const data =
        params &&
        makeHttpParams({
          Keywords: params.Keywords,
          isActive: params.status ? params.status === 1 : null,
          access: params.access,
          Groups: params.tenants,
          PageNumber: params.page,
          MaxResultCount: params.pageSize,
        });

    switch (params.sortField) {
      case 'name': {
        params['Sort.Field'] = '0';
        break;
      }
      case 'access': {
        params['Sort.Field'] = 1;
        break;
      }
      case 'isPublished': {
        params['Sort.Field'] = 2;
        break;
      }
      case 'lastModified': {
        params['Sort.Field'] = 3;
        break;
      }
    }
    params['Sort.IsAsc'] = params.sortType && params.sortType === 'asc';

    if (params['Sort.Field']) {
      data['Sort.Field'] = params['Sort.Field'];
      data['Sort.IsAsc'] = params['Sort.IsAsc'];
    }

    this.http
        .get(this.baseUrl + '/api/services/app/Program/GetAllPrograms', {
          params: data,
        })
        .subscribe(
            (response: GetAllProgramsResp) =>
                subject.next({
                  programs: response.result.items,
                  programsCount: response.result.totalCount,
                }),
            this.handleError
        );
    return subject;
  }

  /* GET FILTERED PROGRAMS COUNT */
  public getFilteredProgramsCount = (params?: GetAllProgramsParams): Subject<number> => {
    const subject = new Subject<number>();
    const data =
      params &&
      makeHttpParams({
        Keywords: params.Keywords,
        IsActive: params.status ? params.status === 1 : null,
        Access: params.access,
        Tenants: params.tenants,
        SkipCount: params.page * params.pageSize,
        MaxResultCount: params.pageSize,
      });

    this.http
      .get(this.baseUrl + '/api/services/app/Program/GetAllFilteredProgramsCount', { params: data })
      .subscribe((response: { result: number }) => subject.next(response.result), this.handleError);
    return subject;
  }

  public getProgramAssociation = (programId: number): Subject<ProgramAssociation[]> => {
    const subject = new Subject<ProgramAssociation[]>();

    let params;
    if (this.authService.ImpersonateTenantId) {
      params = makeHttpParams({ programId,  ImpersonateTenantId: this.authService.ImpersonateTenantId});
    } else {
      params = makeHttpParams({ programId});
    }
    this.http
      .get(this.baseUrl + '/api/services/app/Program/GetProgramAssociation', {
        params,
      })
      .subscribe(
        (response: { result: ProgramAssociation[] }) => subject.next(response.result),
        this.handleError
      );
    return subject;
  }

  /* CREATE PROGRAM */
  public createProgram = (programData: ProgramDTO): Subject<ProgramTableItem> => {
    const subject = new Subject<ProgramTableItem>();

    let data: FormData;
      if (this.authService.ImpersonateTenantId) {
          data = this.getProgramsCreateModel(programData,  this.authService.ImpersonateTenantId);
      } else {
          data = this.getProgramsCreateModel(programData);
      }
    this.http
      .post(this.baseUrl + '/api/services/app/Program/CreateProgram', data)
      .subscribe(
        (response: { result: ProgramTableItem }) => subject.next(response.result),
        this.handleError
      );
    return subject;
  }

  /* CREATE ASSOCIATION ORDER FOR PROGRAM */
  public createAssociationOrder = (params: AssociationOrderParams): Subject<SuccessResp> => {
    const subject = new Subject<SuccessResp>();
    this.http
      .put(this.baseUrl + '/api/services/app/Program/CreateOrUpdateProgramAssociation', params)
      .subscribe((response: SuccessResp) => subject.next(response), this.handleError);
    return subject;
  }

  /* UPDATE/EDIT PROGRAM */
  public updateProgram = (programData: ProgramDTO): Subject<SuccessResp> => {
    const subject = new Subject<SuccessResp>();

    const data = this.getProgramsCreateModel(programData);

    this.http
      .put(this.baseUrl + '/api/services/app/Program/UpdateProgram', data)
      .subscribe((response: SuccessResp) => subject.next(response), this.handleError);
    return subject;
  }

  /* UPDATE PROGRAMS ORDER */
  public updateProgramsOrder = (
    programsOrder: ReorderProgramsDTO
  ): Subject<{ success: boolean }> => {
    const subject: Subject<{ success: boolean }> = new Subject();

    this.http
      .put(this.baseUrl + '/api/services/app/Program/ReorderPrograms', programsOrder)
      .subscribe((response: SuccessResp) => subject.next(response), this.handleError);

    return subject;
  }

  /* UPDATE PROGRAM STATUS */
  public updateProgramStatus = (data: UpdateStatusData): Subject<SuccessResp> => {
    const subject = new Subject<SuccessResp>();

    this.http
      .put(this.baseUrl + '/api/services/app/Program/UpdateProgramStatus', data)
      .subscribe((response: SuccessResp) => subject.next(response), this.handleError);

    return subject;
  }

  /* DELETE PROGRAM */
  public deleteProgram = (id: number): Subject<SuccessResp> => {
    const subject = new Subject<SuccessResp>();

    const programId = String(id);
    this.http
      .delete(this.baseUrl + '/api/services/app/Program/DeleteProgram', {
        params: { programId },
      })
      .subscribe((response: SuccessResp) => subject.next(response), this.handleError);

    return subject;
  }

  private mapMultiplePrograms = (programs: any[]) => programs.map(this.mapSingleProgram);

  private mapSingleProgram = (program) => ({
    id: Number(program.id),
    name: program.name,
    themeColor: program.color || program.colorTheme,
    access: program.access,
    groups: program.groups,
    courses: program.courses,
    isActive: program.isActive,
    institutions:
      program.institutions && program.institutions.length > 0
        ? program.institutions.map((i) => i.id)
        : [],
    isPublished: program.isActive,
    description: program.description,
    icon: handleIconPlacement(program.icon),
    backgroundImageUrl: program.backgroundImageUrl,
    lastModified: moment(program.lastModificationTime || new Date()).format('L'),
    lastActivationTime: program.lastActivationTime,
    progressPercentage: program.progressPercentage,
    isCourseAssociationExists: program.isCourseAssociationExists,
    sendNotifications: program.sendNotifications
  })

  private handleError = (err: { statusText: string }) => {
    return throwError(new Error(err.statusText));
  }
}

export interface GetAllProgramsParams {
  Keywords?: string[] | string;
  status?: number;
  access?: number;
  page?: number;
  pageSize?: number;
  tenants?: any;
}

interface GetAllProgramsOutput {
  programs: ProgramTableItem[];
  programsCount: number;
}

export interface UpdateStatusData {
  programId: number;
  isActive: boolean;
  programName?: string;
}

export interface ProgramDTO {
  id?: number;
  name: string;
  groups: any;
  description: string;
  picture?: string;
  backgroundImageUrl?: string;
  access: number;
  icon: string;
  themeColor: string;
  courses?: string[] | undefined;
  institutions?: number[];
  progressPercentage?: number;
  sendNotifications: boolean;
}

export interface ProgramAssociation {
  id: number;
  name: string;
  indexNumber?: number;
  isSelected: boolean;
  isPublished: boolean;
}

export interface ReorderProgramsDTO {
  programOrderDtos: {
    programId: number;
    indexNumber: number;
  }[];
}

interface GetSingleProgramResp {
  result: ProgramTableItem[];
}

interface GetAllProgramsResp {
  result: {
    items: ProgramTableItem[];
    totalCount: number;
  };
}

export interface SuccessResp {
  success: boolean;
}

export interface AssociationOrderParams {
  programId: number;
  associations: any[];
}
