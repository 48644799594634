import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppAuthService, SwitchAccountDto } from '@shared/auth/app-auth.service';
import { MatDialog } from '@angular/material';
import { Subject } from 'rxjs';

import { SwitchAccountComponent } from '@shared/switch-account/switch-account.component';
import { InstitutionServiceProxy } from '@shared/service-proxies/service-proxies';
import { AccountsService } from '@admin-panel/pages/accounts/accounts.service';
import { AppConsts } from '@shared/AppConsts';
import { TenantService } from '../../new_services/tenant.service';

@Component({
    selector: 'impersonate-bar',
    templateUrl: './impersonate-bar.component.html',
    styleUrls: ['./impersonate-bar.less']
})
export class ImpersonateBarComponent implements OnInit {
    barEnabled: boolean;

    tenantName = 'Loading...';
    hostName = AppConsts.userManagement.defaultHostInstitutionName;

    constructor(
        private authService: AppAuthService,
        private institutionServiceProxy: InstitutionServiceProxy,
        private accountsService: AccountsService,
        private tenantService: TenantService,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private router: Router
    ) { }

    ngOnInit() {
        this.subscribeToChecker();
        this.handleDisplaying();
    }

    onSwitchAccount() {
        const objRequest = {
            PageNumber: 1,
            MaxResultCount: 1000
        };
        this.tenantService.getGetAll(objRequest).subscribe((res: any) => {

            this.dialog.open(SwitchAccountComponent, {
                width: '600px',
                height: '328px',
                disableClose: true,
                position: { top: '150px' },
                data: { institutionList: res.result.items }
            })
                .afterClosed()
                .subscribe((response: SwitchAccountDto) => response &&
                    this.setImpersonateData(response)
                );
        });
    }

    onReturnToHost() {
        this.setImpersonateData(null);
    }

    private setImpersonateData(data?: any) {
        const variable = this.authService.impersonateVar;

        if (data && data.institutionId) {
            const tenantId = data.institutionId;
            this.authService.ImpersonateTenantId = tenantId;
            localStorage.setItem(variable, tenantId);
        } else {
            this.authService.ImpersonateTenantId = undefined;
            localStorage.removeItem(variable);
        }

        if (this.router.url.includes('/app/programs')) {
            this.router.navigate(['/app/programs'])
                .then(() => {
                    window.location.reload();
                });
        } else {
            window.location.reload();
        }

    }

    private handleDisplaying() {
        const variable = this.authService.impersonateVar;
        const accountId = localStorage.getItem(variable);

        if (!!accountId) {
            this.barEnabled = true;
        } else {
            this.barEnabled = false;
            return;
        }

        this.accountsService.getAccountSingle(accountId).subscribe(response => {
            if (response && response.name) {
                this.tenantName = response.name;
                this.cdr.detectChanges();
            }
        });
    }

    private subscribeToChecker() {
        this.authService.impersonateReloadRequired.subscribe(() => {
            this.handleDisplaying();
        });
    }
}
