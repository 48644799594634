import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { MobileCheckSuccess } from '../actions/common.actions';
import { COMMON_ACTION_TYPES } from '../action-types/common.action-types';
import { map } from 'rxjs/operators';
import { MobileService } from '@shared/mobile/mobile.service';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';

@Injectable()
export class CommonEffects {

    /**
     * Initialization of user device determination
     * if success dispatches MobileCheckSuccess
     */
    @Effect()
    initMobileCheck$: Observable<Action> = this.actions$.pipe(
        ofType(COMMON_ACTION_TYPES.INIT_MOBILE_CHECK),
        map(() => MobileCheckSuccess(this.mobileService.isMobile) )
    );

    constructor(
        private actions$: Actions,
        private mobileService: MobileService) {}
}
