import { Directive, Input, ElementRef, HostListener, ComponentRef, AfterViewInit, OnDestroy } from '@angular/core';
import { OverlayRef, Overlay, OverlayPositionBuilder, ConnectedPosition } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CustomTooltipComponent } from './custom-tooltip.component';

export type TooltipType = 'warning' | 'critical';
export type TooltipPosition = 'top' | 'right' | 'bottom' | 'left';

@Directive({
  selector: '[customTooltip]'
})
export class CustomTooltipDirective implements AfterViewInit, OnDestroy {

  @Input() customTooltip: boolean;
  @Input('tooltipHtml') html = '';
  @Input('tooltipText') text = '';
  @Input('tooltipPosition') position: TooltipPosition = 'top';
  @Input('tooltipType') type: TooltipType | undefined;
  @Input('tooltipStyles') styles = {};
  @Input('tooltipStylesDefault') stylesDefault: boolean;
  @Input('tooltipPersist') persist = false;
  @Input('tooltipOffset') offset = 0;

  private overlayRef: OverlayRef;
  private setElementTimeout: number;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) { }

  get isRenderingDeprecated(): boolean {
    return !this.customTooltip && this.customTooltip !== undefined;
  }

  ngAfterViewInit() {
    // calling it with a small timeout, otherwise there might be problems with determination of position
    this.setElementTimeout = window.setTimeout(() => {
      const positionStrategy = this.overlayPositionBuilder
        .flexibleConnectedTo(this.elementRef)
        .withPositions([this.getPositionsArray()]);
      this.overlayRef = this.overlay.create({ positionStrategy });
      if (this.persist) {
        this.show();
      }
    }, 300);
  }

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
    window.clearTimeout(this.setElementTimeout);
  }

  @HostListener('mouseenter')
  show() {
    if (!this.isRenderingDeprecated && this.overlayRef && !(this.persist && this.overlayRef.hasAttached())) {
      const tooltipRef: ComponentRef<CustomTooltipComponent>
        = this.overlayRef.attach(new ComponentPortal(CustomTooltipComponent));
      tooltipRef.instance.html = this.html;
      tooltipRef.instance.text = this.text;
      tooltipRef.instance.styles = this.getDefaultStyles();
      tooltipRef.instance.position = this.position;
      tooltipRef.instance.type = this.type;
      tooltipRef.instance.persist = this.persist;
      tooltipRef.instance.offset = this.offset;
    }
  }

  @HostListener('mouseleave')
  hide() {
    if (!this.isRenderingDeprecated && !this.persist && this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  private getDefaultStyles() {
    if (this.stylesDefault) {
      return {
        'font-family': 'tecnaregular',
        'font-size': '12px',
        'background-color': 'black',
        'width': '220px',
        'color': '#f1f1f1',
        'padding': '6px 10px'
      };
    }

    return this.styles;
  }

  private getPositionsArray(): ConnectedPosition {
    switch (this.position) {
      case 'top':
        return { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom', offsetY: -10 };
      case 'right':
        return { originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center', offsetX: 10 };
      case 'bottom':
        return { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', offsetY: 10 };
      case 'left':
        return { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetX: -10 };
    }
  }
}
