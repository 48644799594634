import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {NotFoundComponent} from '@shared/not-found/not-found.component';
import {CertificateComponent} from './certificate/show-certificate/certificate.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/app/programs',
        pathMatch: 'full'
    },
    {
        path: 'account',
        loadChildren: () => import('account/account.module').then(m => m.AccountModule), // Lazy load account module
        data: {preload: true}
    },
    {
        path: 'certificate/:id',
        component: CertificateComponent
    },
    {
        path: 'app',
        loadChildren: () => import('app/app.module').then(m => m.AppModule), // Lazy load account module
        data: {preload: true}
    },
    {
        path: 'admin',
        loadChildren: () => import('admin-panel/admin-panel.module').then(m => m.AdminPanelModule), // Lazy load admin module
        data: {preload: true}
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: []
})
export class RootRoutingModule {
}
