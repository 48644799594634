import { Injectable, Optional, Inject } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  API_BASE_URL,
  InstitutionServiceProxy,
  InstitutionResponseDto,
  InstitutionDto,
} from '@shared/service-proxies/service-proxies';

import { dataURItoBlob } from '@shared/service-proxies/service-proxies.utils';

import { CustomTableItem } from '@admin-panel/pages/institutions/table-template/custom-item.model';
import { ToastrService } from '@shared/toastr/toastr.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class InstitutionsService {
  baseUrl: string;
  moment: moment.Moment;
  public institutionsSubject = new BehaviorSubject<InstitutionResponseDto[]>(
    null
  );

  constructor(
    private http: HttpClient,
    private institutionsServiceProxy: InstitutionServiceProxy,
    private toastr: ToastrService,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    this.baseUrl = baseUrl;
  }

  public retrieveInstitutions() {
    this.institutionsServiceProxy
      .getInstitutions(undefined, undefined, [], undefined)
      .subscribe((result) => {
        this.institutionsSubject.next(result.institutions);
        this.institutionsSubject.complete();
      });
  }

  /**
   * Returns picture URI based on conditions
   ** If picture already uploaded we need to remove BASE64 meta
   ** If it's newly uploaded photo, just return URI with BASE64 meta
   */
  public getNormalizedPictureURI(pictureURI: string) {
    const isPictureFromServerRegExp = new RegExp(/(http(s?)):\/\//i);
    if (isPictureFromServerRegExp.test(pictureURI)) {
      return '';
    }
    return pictureURI;
  }

  public getInstitutionCreateModel(
    institutionData: InstitutionCreationData & { id?: string }
  ) {
    const institutionCreateModel = new FormData();

    const image = dataURItoBlob(
      this.getNormalizedPictureURI(institutionData.image)
    );

    const mainLogo = dataURItoBlob(
      this.getNormalizedPictureURI(institutionData.mainLogo)
    );
    const loginLogo = dataURItoBlob(
      this.getNormalizedPictureURI(institutionData.loginLogo)
    );

    if (institutionData.id) {
      institutionCreateModel.append('Id', institutionData.id);
    }

    institutionCreateModel.append('Name', institutionData.name);
    institutionCreateModel.append('Description', institutionData.description);
    institutionCreateModel.append('VelocityDid', institutionData.velocityDid);
    institutionCreateModel.append('SubscriptionStartDate', moment(institutionData.subscriptionStartDate).toISOString());
    institutionCreateModel.append('SubscriptionRenewalDate', moment(institutionData.subscriptionRenewalDate).toISOString());
    institutionCreateModel.append('BillingFrequency', institutionData.billingFrequency);
    institutionCreateModel.append('MaximumUsers', institutionData.maxUsers);





    image
      ? institutionCreateModel.append('Picture', image)
      : institutionCreateModel.append('RemovePicture', 'false');

    mainLogo
      ? institutionCreateModel.append('MainLogo', mainLogo)
      : institutionCreateModel.append('RemoveMainLogo', 'false');

    loginLogo
      ? institutionCreateModel.append('LoginLogo', loginLogo)
      : institutionCreateModel.append('RemoveLoginLogo', 'false');

    institutionCreateModel.append(
      'IsCustomizationEnabled',
      institutionData.isCustomizationEnabled ? 'true' : 'false'
    );
    institutionCreateModel.append(
      'IsHideFooter',
      institutionData.isHideFooter ? 'true' : 'false'
    );
    if (institutionData.subdomain) {
      institutionCreateModel.append('Subdomain', institutionData.subdomain);
    }

    return institutionCreateModel;
  }

  public getInstitutions = (
    params?: any
  ): Observable<{
    institutions: CustomTableItem[];
    institutionsNumber: number;
  }> => {
    const _params: GetInstitutionParams | any = {
      PageNumber: String(params.pageIndex + 1),
      maxResultCount: String(params.pageSize),
      Keywords: this.handleKeywords(params.search),
    };
    if (params.sortField) {
      _params['Sort.Field'] = params.sortField;
      switch (params.sortField) {
        case 'name': {
          _params['Sort.Field'] = 1;
          break;
        }
        case 'accounts': {
          _params['Sort.Field'] = 2;
          break;
        }
        case 'isActive': {
          _params['Sort.Field'] = 3;
          break;
        }
        case 'lastModified': {
          _params['Sort.Field'] = 4;
          break;
        }
      }
      _params['Sort.IsAsc'] = params.sortType === 'asc' ? true : 'false';
    }

    if (params.status) {
      _params.isActive = params.status === 1;
    }

    return this.http
      .get(this.baseUrl + '/api/services/app/Institution/GetInstitutions', {
        params: _params,
      })
      .pipe(
        map((response: { result }) => response.result),
        map((result) => ({
          institutions: this.mapInstitutions(result.items),
          institutionsNumber: result.totalCount,
        }))
      );
  }

  public getInstitutionById = (id: string): Subject<EditInstitutionItem> => {
    const subject: Subject<EditInstitutionItem> = new Subject();
    this.http
      .get(this.baseUrl + '/api/services/app/Institution/GetInstitutionById', {
        params: { institutionId: id },
      })
      .subscribe(
        (response: { result }) =>
          subject.next(this.mapInstitutionSingle(response.result)),
        this.handleError
      );
    return subject;
  }

  public checkInstitutionNameExists = (
    name: string,
    institutionId?: number
  ): Subject<boolean> => {
    const subject = new Subject<boolean>();
    const params = {
      TenantId: String(institutionId),
      Name: name,
    };

    if (!institutionId) {
      delete params.TenantId;
    }

    this.http
      .get(
        this.baseUrl +
          '/api/services/app/Tenant/CheckTenantNameExists',
        { params }
      )
      .subscribe(
        (response: { result: boolean }) => subject.next(response.result),
        this.handleError
      );

    return subject;
  }

  public getFilterResultLength = (params: {
    search?: string;
    status: number;
  }): Subject<number> => {
    const subject: Subject<number> = new Subject();
    this.http
      .get(
        this.baseUrl +
          '/api/services/app/Institution/CountFilteredInstitutions',
        {
          params: {
            Keywords: this.handleKeywords(params.search),
            isActive: params.status === 1,
          } as GetFilterResultLengthParams,
        }
      )
      .subscribe(
        (response: { result }) => subject.next(response.result),
        this.handleError
      );
    return subject;
  }

  public createInstitution = (
    institutionData: InstitutionCreationData
  ): Subject<InstitutionDto> => {
    const subject = new Subject<InstitutionDto>();

    const institutionCreateModel = this.getInstitutionCreateModel(
      institutionData
    );

    this.http
      .post(
        this.baseUrl + '/api/services/app/Institution/CreateInstitution',
        institutionCreateModel
      )
      .subscribe(
        (response: { result: InstitutionDto }) => subject.next(response.result),
        this.handleError
      );
    return subject;
  }

  public updateInstitution = (
    updateData: InstitutionCreationData
  ): Subject<SuccessResp> => {
    const subject = new Subject<SuccessResp>();

    const institutionUpdateModel = this.getInstitutionCreateModel(updateData);

    this.http
      .put(
        this.baseUrl + '/api/services/app/Institution/UpdateInstitution',
        institutionUpdateModel
      )
      .subscribe(
        (response: SuccessResp) => subject.next(response),
        this.handleError
      );
    return subject;
  }

  public changeStatus = (data: {
    id: string;
    isActive: boolean;
  }): Subject<SuccessResp> => {
    const subject = new Subject<SuccessResp>();
    this.http
      .put(
        this.baseUrl + '/api/services/app/Institution/UpdateInstitutionStatus',
        data
      )
      .subscribe(
        (response: SuccessResp) => subject.next(response),
        this.handleError
      );
    return subject;
  }

  public deleteInstitution = (id: string): Subject<SuccessResp> => {
    const subject = new Subject<SuccessResp>();
    this.http
      .delete(
        this.baseUrl + '/api/services/app/Institution/DeleteInstitution',
        {
          params: {
            institutionId: id,
          },
        }
      )
      .subscribe(
        (response: SuccessResp) => subject.next(response),
        this.handleError
      );
    return subject;
  }

  handleError = (error: HttpErrorResponse) => {
    this.toastr.showError('An error occurred', error.error.error.message);
  }

  private mapInstitutions = (
    institutions: InstitutionsResponse[]
  ): CustomTableItem[] => institutions.map(this.mapInstitutionSingle)

  private mapInstitutionSingle = (
    institution: InstitutionsResponse
  ): EditInstitutionItem => ({
    id: String(institution.id),
    name: institution.name,
    description: institution.description || undefined,
    imageSrc: institution.pictureUrl,
    isActive: institution.isActive || undefined,
    billingFrequency: institution.billingFrequency,
    maximumUsers: institution.maximumUsers,
    subscriptionRenewalDate: institution.subscriptionRenewalDate,
    subscriptionStartDate: institution.subscriptionStartDate,
    lastModified: moment(institution.lastModificationTime).format('L'),
    accounts: String(institution.accountsCount),
    usersCount: institution.usersCount,
    status: institution.isActive ? 'Active' : 'Deactivated',
    // White Labeling
    isCustomizationEnabled: institution.isCustomizationEnabled,
    isHideFooter: institution.isHideFooter,
    subdomain: institution.subdomain,
    velocityDid: institution.velocityDid,
    mainLogo: this.addBase64Meta(institution.mainLogo),
    loginLogo: this.addBase64Meta(institution.loginLogo),
  })

  private handleKeywords = (param: string): string[] =>
    typeof param === 'string'
      ? param.split(' ').filter((el) => el && el !== ' ')
      : []

  private addBase64Meta = (image: string): string =>
    image
      ? image.includes('data:image')
        ? image
        : 'data:image/png;base64,' + image
      : ''
}

export interface QueryParams {
  pageSize: number;
  pageIndex: number;
  search?: string;
  status?: number;
  isActive?: boolean;
}

interface SuccessResp {
  success: boolean;
}

interface GetInstitutionParams extends HttpParams {
  skipCount: string;
  isActive?: boolean;
  Keywords?: string[];
  maxResultCount: string;
}

interface GetFilterResultLengthParams extends HttpParams {
  isActive: boolean;
  Keywords: string[];
}

interface WhiteLabelingAddition {
  description?: string;
  isCustomizationEnabled?: boolean;
  isHideFooter?: boolean;
  subdomain?: string;
  mainLogo?: string;
  loginLogo?: string;
}

interface InstitutionsResponse extends WhiteLabelingAddition {
  id: number;
  name: string;
  picture: string;
  isActive: boolean;
  accountsCount: number;
  lastModificationTime: string;
  pictureUrl?: string;
  velocityDid?: string;
  billingFrequency: number;
  maximumUsers: number;
  subscriptionRenewalDate: Date;
  subscriptionStartDate: Date;
  usersCount: number;
}

export interface InstitutionCreationData extends WhiteLabelingAddition {
  id?: string;
  name: string;
  description?: string;
  image?: string;
  isCustomizationEnabled?: boolean;
  isHideFooter?: boolean;
  subdomain?: string;
  velocityDid?: string;
  mainLogo?: string;
  loginLogo?: string;
  subscriptionStartDate: string;
  subscriptionRenewalDate: string;
  billingFrequency: any;
  maxUsers: any;
}

export interface EditInstitutionItem
  extends CustomTableItem,
    WhiteLabelingAddition {
  description?: string;
  velocityDid?: string;
  billingFrequency: number;
  maximumUsers: number;
  subscriptionRenewalDate: Date;
  subscriptionStartDate: Date;
  usersCount: number;
}
