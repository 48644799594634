export class AppConsts {

    static remoteServiceBaseUrl: string;
    static azureInsightsApiKey: string;
    static isDevelopment: boolean;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin',
        defaultHostTenantName: 'Domain-U (HOST)',
        defaultHostInstitutionName: 'Domain-U (HOST)',
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'VTL'
    };

    static readonly authorization = {
        encryptedAuthTokenName: 'enc_auth_token'
    };

    static singleVideoModeViewName = 'Class';
    static multiVideoModeDefaultViewName = 'Instructor View';
}

export const MIME_TYPES_HASHMAP = {
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'text/plain': ['.txt'],
    'text/csv': ['.csv'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/zip': ['.zip'],
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/bmp': ['.bmp'],
};

export const MEGABYTE_SIZE = 2 ** 20;
