import hljs from 'highlight.js';
import { highlight } from 'highlight.js/lib/highlight';
import { unescape } from 'lodash-es';

/* Languages to register */
import javascript from 'highlight.js/lib/languages/javascript';
import xml from 'highlight.js/lib/languages/xml';
import cpp from 'highlight.js/lib/languages/cpp';
import css from 'highlight.js/lib/languages/css';
import java from 'highlight.js/lib/languages/java';
import json from 'highlight.js/lib/languages/json';
import php from 'highlight.js/lib/languages/php';
import ruby from 'highlight.js/lib/languages/ruby';
import python from 'highlight.js/lib/languages/python';
import cs from 'highlight.js/lib/languages/cs';

const preStyle = `
    background-color: white;
    border: 1px solid #efeded;
    border-radius: 8px;
    margin: 14px 0;
    padding: 0px;
    width: 720px;
`;

const codeStyle = `
    background-color: white;
`;

/*
    - Receives any string with code snippets inside, wrapped with <pre class="language-
        (provided by the TinyMCE Code Sample plugin)
    - Returnes the same string, except the tagged scripts are highlighted and embraced with <pre><code> tags
*/
export default class CodeSnippetHelper {
    constructor() {
        hljs.registerLanguage('xml', xml);
        hljs.registerLanguage('javascript', javascript);
        hljs.registerLanguage('json', json);
        hljs.registerLanguage('css', css);
        hljs.registerLanguage('php', php);
        hljs.registerLanguage('ruby', ruby);
        hljs.registerLanguage('python', python);
        hljs.registerLanguage('java', java);
        hljs.registerLanguage('cs', cs);
        hljs.registerLanguage('cpp', cpp);
    }

    transformFullTextWithSnippets(globalText: string): string {
        let transformedText = globalText;
        const matches = globalText.match(/<pre class="language-.*?">(.|\n)*?<\/pre>/gm);
        matches && matches.forEach(match => {
            transformedText = this.transformCodeSnippet(transformedText, match);
        });
        return transformedText;
    }

    private transformCodeSnippet(globalText: string, match: string): string {
        const text = globalText;
        const openTagInd = match.indexOf(`<code>`);
        const closeTagInd = match.indexOf(`</code>`) + 'code'.length + 3;
        const codePiece = match.slice(openTagInd, closeTagInd);

        const languageByTinyMCEPlugin = match.substring(match.indexOf('-') + 1, match.indexOf('">'));
        let languageByHighlightJs: string;

        switch (languageByTinyMCEPlugin) {
            case 'markup':
                languageByHighlightJs = 'xml';
                break;
            default:
                languageByHighlightJs = languageByTinyMCEPlugin;
                break;
        }

        const codeSnippet = unescape(codePiece)
            .replace(/<code>/g, '')
            .replace(/<\/code>/g, '');

        const codeSnippetHighlighted = highlight(languageByHighlightJs, codeSnippet, true).value;

        const codeSnippetFull =
            `<pre style="${preStyle}"><code style="${codeStyle}" class="hljs">${codeSnippetHighlighted}</code></pre>`;

        return text.replace(match, codeSnippetFull);
    }
}
