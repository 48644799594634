import {Component, Input, Output, EventEmitter} from '@angular/core';

interface NavigationTab {
    id: number;
    title: string;
    disabled?: boolean;
}

@Component({
    selector: 'tabs-navigation',
    templateUrl: './tabs-navigation.component.html',
    styleUrls: ['./tabs-navigation.styles.less']
})
export class TabsNavigationComponent {
    @Input() tabs: NavigationTab[];
    @Input() activeTab: number;
    @Input() message = '';
    @Output() onTabClick: EventEmitter<any> = new EventEmitter();

    onTabClicked(tabId: number) {
        const currentTab = this.tabs.find((element) => element.id === tabId);
        if (!currentTab.disabled) {
            this.onTabClick.emit(tabId);
        }
    }
}
