import { Injectable } from '@angular/core';
import { LogoTypePipe } from '@shared/pipes/logo.pipe';

@Injectable()
export class WhiteLabelService {
  public mainLogo: string = null;
  public loginLogo: string = null;
  public favIcon: string = null;
  public isCustomizationEnabled = false;
  public isHideFooter = true;

  public defaultFavIcon = 'https://domainumediastorage.blob.core.windows.net/logos/logo.png';

  setWhiteLabel(whiteLabelData: any) {
    const logoPipe = new LogoTypePipe().transform;

    this.mainLogo = logoPipe(whiteLabelData.mainLogo);
    this.loginLogo = logoPipe(whiteLabelData.loginLogo);
    this.favIcon = logoPipe(whiteLabelData.favIcon);

    this.isCustomizationEnabled = whiteLabelData.isCustomizationEnabled;
    this.isHideFooter = whiteLabelData.isHideFooter;

    if (this.favIcon) {
      this.changeFavIcon();
    }
  }

  changeFavIcon() {
    const link = document.querySelector('link[rel*="icon"]') || document.createElement('link');
    (link as any).href = this.favIcon;
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}
