import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import * as ApiServiceProxies from './service-proxies';
import { CustomInterceptor } from 'config/customInterceptor';
import { AbpHttpInterceptor } from 'config/abp-interceptor';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.InstitutionServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.CourseServiceProxy,
        ApiServiceProxies.ClassServiceProxy,
        ApiServiceProxies.ClassVideoServiceProxy,
        ApiServiceProxies.InstructorServiceProxy,
        ApiServiceProxies.ProgramServiceProxy,
        ApiServiceProxies.MaterialServiceProxy,
        ApiServiceProxies.QuizServiceProxy,
        ApiServiceProxies.ClassChapterServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ]
})
export class ServiceProxyModule { }
