import {
    mergeMap as _observableMergeMap,
    catchError as _observableCatch,
    tap as _observableTap,
    catchError as _observableCatchError
} from 'rxjs/operators';
import {
    Observable,
    throwError as _observableThrow,
    of as _observableOf,
    BehaviorSubject,
} from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpResponse,
    HttpResponseBase
} from '@angular/common/http';
import { SwaggerException, InstructorDto } from '@shared/service-proxies/service-proxies';
import { getRemoteServiceBaseUrl } from 'root.module';
import { AuthCookieService } from '@app/services/auth-cookie.service';
import { url } from 'inspector';

export interface IProgramOverviewDto {
    name: string | undefined;
    description: string | undefined;
    icon: string | undefined;
    backgroundImage: string | undefined;
    backgroundImageUrl: string | undefined;
    colorTheme: string | undefined;
    coursesCount: number | undefined;
    classesCount: number | undefined;
    instructorsCount: number | undefined;
    progressPercentage: number | undefined;
    id: number | undefined;
    courses: object | undefined;
    instructors: InstructorDto[] | undefined;
    indexNumber: number | undefined;
}

export class ProgramOverviewDto implements IProgramOverviewDto {
    static fromJS(data: any): ProgramOverviewDto {
        data = typeof data === 'object' ? data : {};
        const result = new ProgramOverviewDto();
        result.init(data);
        return result;
    }

    name: string | undefined;
    description: string | undefined;
    icon: string | undefined;
    backgroundImage: string | undefined;
    backgroundImageUrl: string | undefined;
    colorTheme: string | undefined;
    coursesCount: number | undefined;
    classesCount: number | undefined;
    instructorsCount: number | undefined;
    progressPercentage: number | undefined;
    id: number | undefined;
    courses: object | undefined;
    instructors: InstructorDto[] | undefined;
    indexNumber: number | undefined;

    constructor(data?: IProgramOverviewDto) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    init(data?: any) {
        if (data) {
            this.name = data['name'];
            this.description = data['description'];
            this.icon = data['icon'];
            this.backgroundImage = data['backgroundImage'];
            this.backgroundImageUrl = data['backgroundImageUrl'];
            this.colorTheme = data['colorTheme'];
            this.coursesCount = data['coursesCount'];
            this.classesCount = data['classesCount'];
            this.instructorsCount = data['instructorsCount'];
            this.progressPercentage = data['progressPercentage'];
            this.id = data['id'];
            this.courses = data['courses'];
            this.instructors = data['instructors'];
            this.indexNumber = data['indexNumber'];
        }
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['name'] = this.name;
        data['description'] = this.description;
        data['icon'] = this.icon;
        data['backgroundImage'] = this.backgroundImage;
        data['backgroundImageUrl'] = this.backgroundImageUrl;
        data['colorTheme'] = this.colorTheme;
        data['coursesCount'] = this.coursesCount;
        data['classesCount'] = this.classesCount;
        data['instructorsCount'] = this.instructorsCount;
        data['progressPercentage'] = this.progressPercentage;
        data['id'] = this.id;
        data['indexNumber'] = this.indexNumber;

        return data;
    }

    clone(): ProgramOverviewDto {
        const json = this.toJSON();
        const result = new ProgramOverviewDto();
        result.init(json);
        return result;
    }
}

export interface IListResultDtoOfProgramOverviewDto {
    items: ProgramOverviewDto[] | undefined;
}

export class ListResultDtoOfProgramOverviewDto
    implements IListResultDtoOfProgramOverviewDto {
    static fromJS(data: any): ListResultDtoOfProgramOverviewDto {
        data = typeof data === 'object' ? data : {};
        const result = new ListResultDtoOfProgramOverviewDto();
        result.init(data);
        return result;
    }

    items: ProgramOverviewDto[] | undefined;

    constructor(data?: IListResultDtoOfProgramOverviewDto) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    init(data?: any) {
        if (data) {
            if (data['items'] && data['items'].constructor === Array) {
                this.items = [];
                for (const item of data['items']) {
                    this.items.push(ProgramOverviewDto.fromJS(item));
                }
            }
        }
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (this.items && this.items.constructor === Array) {
            data['items'] = [];
            for (const item of this.items) {
                data['items'].push(item.toJSON());
            }
        }
        return data;
    }

    clone(): ListResultDtoOfProgramOverviewDto {
        const json = this.toJSON();
        const result = new ListResultDtoOfProgramOverviewDto();
        result.init(json);
        return result;
    }
}

@Injectable({
    providedIn: 'root'
})
export class ProgramsService {
    public programs: IProgramOverviewDto[] = [];
    public programsSubject = new BehaviorSubject<IProgramOverviewDto[]>(null);
    protected jsonParseReviver:
        | ((key: string, value: any) => any)
        | undefined = undefined;
    private http: HttpClient;

    constructor(@Inject(HttpClient) http: HttpClient, private _authCookieService: AuthCookieService) {
        this.http = http;
    }

    deleteUser() {
        let url_ =
            getRemoteServiceBaseUrl() + '/api/services/app/UserProfile/RequestDelete';

        url_ = url_.replace(/[?&]$/, '');

        const options_: any = {
            observe: 'response',
            headers: new HttpHeaders({
                Accept: 'application/json',
                Authorization: `Bearer ${this._authCookieService.getAuthCookie()}`
            })
        };

        return this.http.get(url_, options_);
    }

    /**
     * @return Success
     */
    getPrograms(): Observable<IProgramOverviewDto[]> {
        let url_ =
            getRemoteServiceBaseUrl() + '/api/services/app/Program/GetPrograms';

        url_ = url_.replace(/[?&]$/, '');

        const options_: any = {
            observe: 'response',
            headers: new HttpHeaders({
                Accept: 'application/json',
                Authorization: `Bearer ${this._authCookieService.getAuthCookie()}`
            })
        };
        return this.http
            .request('get', url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGetPrograms(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGetPrograms(<any>response_);
                        } catch (e) {
                            return <Observable<IProgramOverviewDto[]>>(
                                (<any>_observableThrow(e))
                            );
                        }
                    } else {
                        return <Observable<IProgramOverviewDto[]>>(
                            (<any>_observableThrow(response_))
                        );
                    }
                })
            );
    }

    getShortPrograms(): Observable<any> {
        let url_ =
            getRemoteServiceBaseUrl() + '/api/services/app/Program/GetShortPrograms';

        url_ = url_.replace(/[?&]$/, '');

        const options_: any = {
            observe: 'response',
            headers: new HttpHeaders({
                Accept: 'application/json',
                Authorization: `Bearer ${this._authCookieService.getAuthCookie()}`
            })
        };
        return this.http.get(url_, options_);
    }

    getProgramAssociation(data) {
        let url_ =
            getRemoteServiceBaseUrl() + '/api/services/app/Tenant/GetTenantAssociation?tenantId=' + data.id;
        url_ = url_.replace(/[?&]$/, '');
        const options_: any = {
            observe: 'response',
            headers: new HttpHeaders({
                Accept: 'application/json',
                Authorization: `Bearer ${this._authCookieService.getAuthCookie()}`
            })
        };
        return this.http.get(url_, options_);
    }

    updateProgramAssociation(data) {
        let url_ =
            getRemoteServiceBaseUrl() + '/api/services/app/Tenant/CreateOrUpdateTenantAssociation';
        url_ = url_.replace(/[?&]$/, '');
        const options_: any = {
            observe: 'response',
            headers: new HttpHeaders({
                Accept: 'application/json',
                Authorization: `Bearer ${this._authCookieService.getAuthCookie()}`
            })
        };

        return this.http.put(url_, data, options_);
    }

    getProgramsComplete(): Observable<IProgramOverviewDto[]> {
        let url_ =
            getRemoteServiceBaseUrl() + '/api/services/app/Program/GetPrograms?CompletedOnly=true';

        url_ = url_.replace(/[?&]$/, '');

        const options_: any = {
            observe: 'response',
            headers: new HttpHeaders({
                Accept: 'application/json',
                Authorization: `Bearer ${this._authCookieService.getAuthCookie()}`
            })
        };

        return this.http
            .request('get', url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGetPrograms(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGetPrograms(<any>response_);
                        } catch (e) {
                            return <Observable<IProgramOverviewDto[]>>(
                                (<any>_observableThrow(e))
                            );
                        }
                    } else {
                        return <Observable<IProgramOverviewDto[]>>(
                            (<any>_observableThrow(response_))
                        );
                    }
                })
            );
    }

    getHeaders() {
        return {
            observe: 'response',
            responseType: 'json',
            headers: new HttpHeaders({
                Accept: 'application/json',
                Authorization: `Bearer ${this._authCookieService.getAuthCookie()}`,
            }),
        };
    }

    getCertificateInfo(courseId, params?) {
        let url_ =
            getRemoteServiceBaseUrl() + `/api/services/app/UserProfile/ShareCertificate/get-certificate-url`;
        url_ = url_.replace(/[?&]$/, '');

        const options: any = {
            params: {
                CourseId: courseId,
            },
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + this._authCookieService.getAuthCookie(),
                'Access-Control-Expose-Headers': 'Content-Disposition',
            }),
        };
        if (params) {
            if (params.ImpersonateTenantId) {
                options.params.ImpersonateTenantId = params.ImpersonateTenantId;
            }
            options.params.ImpersonateUserId = params.ImpersonateUserId;
        }
        return this.http.get<HttpResponse<any>>(url_, options);
    }

    getCertificated(courseId, params?) {
        let url_ =
            getRemoteServiceBaseUrl() + `/api/services/app/UserProfile/${params ? 'DownloadUserCourseCompletionCertificateAsAdmin' : 'DownloadUserCourseCompletionCertificate'}`;
        url_ = url_.replace(/[?&]$/, '');

        const options: any = {
            params: {
                CourseId: courseId,
            },
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + this._authCookieService.getAuthCookie(),
                'Access-Control-Expose-Headers': 'Content-Disposition',
            }),
        };
        if (params) {
            if (params.ImpersonateTenantId) {
                options.params.ImpersonateTenantId = params.ImpersonateTenantId;
            }
            options.params.ImpersonateUserId = params.ImpersonateUserId;
        }
        return this.http.get<HttpResponse<Blob>>(url_, options);
    }


    getProgramsByUserId(params): Observable<IProgramOverviewDto[]> {
        let url_ =
            getRemoteServiceBaseUrl() + '/api/services/app/Program/GetProgramsByUserId';

        url_ = url_.replace(/[?&]$/, '');

        const options: any = {
            params: {
                ImpersonateUserId: params.impersonateUserId,
                CompletedOnly: params.completedOnly,
            },
            ...this.getHeaders(),
        };

        return this.http
            .request('get', url_, options)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGetPrograms(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGetPrograms(<any>response_);
                        } catch (e) {
                            return <Observable<IProgramOverviewDto[]>>(
                                (<any>_observableThrow(e))
                            );
                        }
                    } else {
                        return <Observable<IProgramOverviewDto[]>>(
                            (<any>_observableThrow(response_))
                        );
                    }
                })
            );
    }


    retrievePrograms(completeCallBack, forceUpdate?: boolean) {
        if (!forceUpdate && this.programsSubject.value) {
            completeCallBack();
            return;
        }
        return this.getPrograms().subscribe(
            (result: IProgramOverviewDto[]) => {
                this.programs = result;
                const tempArr = this.programs.sort((a, b) => a.indexNumber - b.indexNumber);
                this.programsSubject.next(result);
            },
            err => err,
            completeCallBack
        );
    }

    protected processGetPrograms(
        response: HttpResponse<any>
    ): Observable<IProgramOverviewDto[]> {
        const status = response.status;

        const _headers: any = {};
        if (response.headers) {
            for (const key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return _observableOf(response.body.result);

        } else if (status !== 200 && status !== 204) {
            throwException(
                'An unexpected server error occurred.',
                status,
                response.statusText,
                _headers
            );
        }
        return _observableOf<IProgramOverviewDto[]>(<any>null);
    }
}

function throwException(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any
): Observable<any> {
    if (result !== null && result !== undefined) {
        return _observableThrow(result);
    } else {
        return _observableThrow(
            new SwaggerException(message, status, response, headers, null)
        );
    }
}
