import { Injectable, ElementRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TopbarService {

  elementRef: ElementRef;
  isExpanded = false;
  elementHeight: number;

  constructor() {}

  setElementRef(elementRef: ElementRef) {
    this.elementRef = elementRef;
    this.elementHeight = this.elementRef.nativeElement.offsetHeight;
  }

  toggleIsExpandedState() {
    this.isExpanded = !this.isExpanded;

    const updateElemHeight = () => {
      window.requestAnimationFrame(() => {
        if (this.elementHeight !== this.elementRef.nativeElement.offsetHeight) {
          this.elementHeight = this.elementRef.nativeElement.offsetHeight;
          return;
        }
        updateElemHeight();
      });
    };
    updateElemHeight();
  }
}
