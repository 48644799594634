export const isDataURI = (dataString: string) => {
  const isDataURIRegExp = RegExp(
    /^(data:)([\w\/\+]+);(charset=[\w-]+|base64).*,(.*)/gi
  );

  return isDataURIRegExp.test(dataString);
};

export const dataURItoBlob = (dataString) => {
  if (!dataString) {
    return null;
  }

  if (isDataURI(dataString)) {
    const byteString = atob(dataString.split(',')[1]);
    const mimeString = dataString
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];

    const dataArrayBlob = new ArrayBuffer(byteString.length);
    const UIntArrayData = new Uint8Array(dataArrayBlob);

    for (let i = 0; i < byteString.length; i++) {
      UIntArrayData[i] = byteString.charCodeAt(i);
    }

    const dataBlob = new Blob([UIntArrayData], { type: mimeString });

    return new File([dataBlob], 'instructorPhoto.png', {
      lastModified: new Date().getMilliseconds(),
      type: mimeString,
    });
  }

  return dataString;
};
