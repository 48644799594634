import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { NgxSmoothDnDModule } from 'ngx-smooth-dnd';

import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { WhiteLabelService } from './white-label/white-label.service';
import { MaterialService } from './material/material.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { AbpPaginationControlsComponent } from './pagination/abp-pagination-controls.component';
import { TableComponent } from './table/table.component';
import { LocalizePipe } from '@shared/pipes/localize.pipe';
import { TransformIconPipe } from '@shared/pipes/icon.pipe';
import { LogoTypePipe } from '@shared/pipes/logo.pipe';
import { AbsoluteLinkPipe } from './pipes/absolute-link.pipe';
import { AbsoluteValuePipe } from './pipes/absolute-value.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { TopBarComponent } from './layout/top-bar/topbar.component';
import { NotFoundComponent } from './not-found/not-found.component';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
} from '@angular/material';

import { CustomTooltipDirective } from './custom-tooltip/custom-tooltip.directive';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';
import { RoleService } from './roles/role.service';
import { InstitutionsService } from './institutions/institutions.service';
import { ToastrService } from './toastr/toastr.service';
import { ProgramsService } from './programs/programs.service';
import { ColorSelectorComponent } from './color-selector/color-selector.component';
import { InstructorService } from './instructors/instructor.service';
import { SwitchAccountComponent } from './switch-account/switch-account.component';
import { AccountsService } from '@admin-panel/pages/accounts/accounts.service';
import { QuizService } from '@shared/quiz/quiz.service';
import { ImpersonateBarComponent } from './impersonate-bar/impersonate-bar.component';
import { StepperComponent } from './stepper/stepper.component';
import { FooterComponent } from './footer/footer.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { NoResultsBtnComponent } from './no-results-btn/no-results-btn.component';
import { TabsNavigationComponent } from './tabs-navigation/tabs-navigation.component';
import { InstructorsThumbsComponent } from './instructors-thumbs/instructors-thumbs.component';
import { ProgressBarCircleComponent } from './progress-bar/progress-bar-circle/progress-bar-circle';

import { SecToMinSecPipe } from './pipes/sec-to-min-sec.pipe';
import { ViewNameToIconCodePipe } from './pipes/view-name-to-icon-code.pipe';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { HighlightSnippetsPipe } from './pipes/highlight-snippets.pipe';
import { TicksToTimeStringPipe } from './pipes/ticks-to-timepoints.pipe';

import { WarningDialogComponent } from './dialog-scenarios/warning-dialog/warning-dialog.component';
import { MobileService } from './mobile/mobile.service';
import { UiService } from './ui/ui.service';
import { SafePipe } from '@shared/pipes/safes.pipe';
import {CertificateComponent} from '../certificate/show-certificate/certificate.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgxPaginationModule,
    EditorModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    NgxSmoothDnDModule,
  ],
  declarations: [
    AbpPaginationControlsComponent,
    NotFoundComponent,
    TopBarComponent,
    TableComponent,
    LocalizePipe,
    TransformIconPipe,
    LogoTypePipe,
    AbsoluteLinkPipe,
    AbsoluteValuePipe,
    SwitchAccountComponent,
    ImpersonateBarComponent,
    StepperComponent,
    FooterComponent,
    SelectSearchComponent,
    NoResultsBtnComponent,
    TabsNavigationComponent,
    SecToMinSecPipe,
    ViewNameToIconCodePipe,
    SafeHTMLPipe,
    SafePipe,
    HighlightSnippetsPipe,
    TicksToTimeStringPipe,
    InstructorsThumbsComponent,
    WarningDialogComponent,
    ProgressBarCircleComponent,
    CustomTooltipDirective,
    CustomTooltipComponent,
    ColorSelectorComponent,
    CertificateComponent
  ],
  exports: [
    AbpPaginationControlsComponent,
    NotFoundComponent,
    TopBarComponent,
    TableComponent,
    ProgressBarCircleComponent,
    LocalizePipe,
    TransformIconPipe,
    LogoTypePipe,
    AbsoluteLinkPipe,
    AbsoluteValuePipe,
    CustomTooltipDirective,
    CustomTooltipComponent,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    ScrollingModule,
    EditorModule,
    ColorSelectorComponent,
    NgxSmoothDnDModule,
    ImpersonateBarComponent,
    StepperComponent,
    FooterComponent,
    SelectSearchComponent,
    NoResultsBtnComponent,
    TabsNavigationComponent,
    SecToMinSecPipe,
    ViewNameToIconCodePipe,
    SafeHTMLPipe,
      SafePipe,
    HighlightSnippetsPipe,
    TicksToTimeStringPipe,
    InstructorsThumbsComponent,
    WarningDialogComponent,
    CertificateComponent
  ],
  providers: [AccountsService, StepperComponent],
  entryComponents: [
    CustomTooltipComponent,
    SwitchAccountComponent,
    WarningDialogComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        AppSessionService,
        AppUrlService,
        AppAuthService,
        WhiteLabelService,
        MobileService,
        AppRouteGuard,
        RoleService,
        ProgramsService,
        InstitutionsService,
        ToastrService,
        InstructorService,
        MaterialService,
        QuizService,
        UiService,
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
      ],
    };
  }
}
