import { Injectable, Inject, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponseBase,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  mergeMap as _observableMergeMap,
  catchError as _observableCatch,
} from 'rxjs/operators';
import {
  Observable,
  throwError as _observableThrow,
  of as _observableOf,
  BehaviorSubject,
  Subject,
} from 'rxjs';
import {
  API_BASE_URL,
  InstructorServiceProxy,
  InstructorNameDto,
  InstructorProfileDto,
  ICreateInstructorDto,
} from '@shared/service-proxies/service-proxies';
import { ToastrService } from '@shared/toastr/toastr.service';
import { dataURItoBlob } from '@shared/service-proxies/service-proxies.utils';
import { AppAuthService } from '@shared/auth/app-auth.service';

const commonHeaderParams = {
  observe: 'response',
  responseType: 'json',
  headers: new HttpHeaders({
    Accept: 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class InstructorService {
  public instructor: Instructor;
  public instructorsSubject = new BehaviorSubject<InstructorNameDto[]>(null);
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;
  private http: HttpClient;
  private baseUrl: string;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    private instructorServiceProxy: InstructorServiceProxy,
    private toastr: ToastrService,
    private authService: AppAuthService,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  public retrieveInstructors() {
    this.instructorServiceProxy.getInstructorNames().subscribe((result) => {
      this.instructorsSubject.next(result);
      this.instructorsSubject.complete();
    });
  }

  commonRequest = (requestType, url_, options_) =>
    this.http.request(requestType, url_, options_).pipe(
      _observableMergeMap((response: any) => {
        return _observableOf(response.body.result);
      })
    )

  public getNormalizedPictureURI(pictureURI: string) {
    const isPictureFromServerRegExp = new RegExp(/(http(s?)):\/\//i);
    if (isPictureFromServerRegExp.test(pictureURI)) {
      return '';
    }
    return pictureURI;
  }

  public getInstructorCreateModel(
    instructorData: InstructorProfileDto & { id?: string; picture?: string }
  ) {
    const instructorCreateModel = new FormData();

    const biography = instructorData.biography !== undefined ? instructorData.biography : '';
    instructorCreateModel.append('FullName', instructorData.fullName);
    instructorCreateModel.append('Biography', biography);

    if (instructorData.websiteLink) {
      instructorCreateModel.append('WebsiteLink', instructorData.websiteLink);
    }

    if (instructorData.videoLink) {
      instructorCreateModel.append('VideoLink', instructorData.videoLink);
    }

    if (instructorData.facebookLink) {
      instructorCreateModel.append('FacebookLink', instructorData.facebookLink);
    }

    if (instructorData.linkedInLink) {
      instructorCreateModel.append('LinkedInLink', instructorData.linkedInLink);
    }

    if (instructorData.instagramLink) {
      instructorCreateModel.append(
        'InstagramLink',
        instructorData.instagramLink
      );
    }

    if (this.authService.ImpersonateTenantId) {
      instructorCreateModel.append('ImpersonateTenantId', this.authService.ImpersonateTenantId);
    }
    if (instructorData.twitterLink) {
      instructorCreateModel.append('TwitterLink', instructorData.twitterLink);
    }

    if (instructorData.id) {
      instructorCreateModel.append('Id', instructorData.id);
    }

    if (instructorData.specialization) {
      instructorCreateModel.append(
        'Specialization',
        instructorData.specialization
      );
    }

    if (instructorData.experience) {
      instructorCreateModel.append('Experience', instructorData.experience);
    }

    const thumbnail = dataURItoBlob(
      this.getNormalizedPictureURI(instructorData.thumbnail)
    );

    const picture = dataURItoBlob(
      this.getNormalizedPictureURI(instructorData.picture)
    );

    if (thumbnail) {
      instructorCreateModel.append('Thumbnail', thumbnail);
    } else {
      instructorCreateModel.append('RemoveThumbnail', 'false');
    }

    if (picture) {
      instructorCreateModel.append('Picture', picture);
    } else {
      instructorCreateModel.append('RemovePicture', 'false');
    }

    return instructorCreateModel;
  }

  public createInstructor(instructorData: any): Subject<InstructorProfileDto> {
    const subject = new Subject<InstructorProfileDto>();
    const instructorCreateModel = this.getInstructorCreateModel(instructorData);
    this.http
      .post(
        this.baseUrl + '/api/services/app/Instructor/CreateInstructor',
        instructorCreateModel
      )
      .subscribe(
        (response: { result: InstructorProfileDto }) =>
          subject.next(response.result),
        this.handleError
      );
    return subject;
  }

  public updateInstructor(instructorData: any): Subject<InstructorProfileDto> {
    const subject = new Subject<InstructorProfileDto>();

    const instructorCreateModel = this.getInstructorCreateModel(instructorData);

    this.http
      .put(
        this.baseUrl + '/api/services/app/Instructor/UpdateInstructor',
        instructorCreateModel
      )
      .subscribe(
        (response: { result: InstructorProfileDto }) =>
          subject.next(response.result),
        this.handleError
      );
    return subject;
  }

  /**
   * @return Success
   */
  getInstructorProfile(params): Observable<Instructor> {
    let url_ =
      this.baseUrl + '/api/services/app/Instructor/GetInstructorProfile';

    url_ = url_.replace(/[?&]$/, '');

    const options_: any = {
      params,
      ...commonHeaderParams,
    };

    return this.commonRequest('get', url_, options_).pipe(
      _observableCatch((response_: any) => {
        if (response_ instanceof HttpResponseBase) {
          try {
            return _observableOf(<any>response_);
          } catch (e) {
            return <Observable<Instructor>>(<any>_observableThrow(e));
          }
        } else {
          return <Observable<Instructor>>(<any>_observableThrow(response_));
        }
      })
    );
  }

  getInstructorFullImage(id: number) {
    const url =
      this.baseUrl + '/api/services/app/Instructor/GetInstructorFullImage';
    const options = { id };

    return this.commonRequest('get', url, options).pipe(
      (response) => response,
      (error) => error
    );
  }

  uploadInstructorResume(
    instructorId: number,
    resumeToUpload: File
  ): Subject<any> {
    const subject = new Subject<any>();
    const url =
      this.baseUrl + '/api/services/app/Material/UploadInstructorMaterial?';
    const formData = new FormData();
    formData.append('file', resumeToUpload);

    this.http
      .post(url, formData, { params: { instructorId: String(instructorId) } })
      .subscribe((_response) => subject.next(_response));

    return subject;
    // .pipe(
    //   _observableCatch((response_) => {
    //     if (response_ instanceof HttpResponseBase) {
    //       try {
    //         return _observableOf(<any>response_);
    //       } catch (e) {
    //         return <Observable<Instructor>>(<any>_observableThrow(e));
    //       }
    //     } else {
    //       return <Observable<Instructor>>(<any>_observableThrow(response_));
    //     }
    //   })
    // );
  }

  handleError = (error: HttpErrorResponse) => {
    this.toastr.showError('An error occurred', error.error.error.message);
  }
}

export class Instructor {
  websiteLink: string;
  videoId: string;
  experience: string;
  id: number;
  userId?: number;
  files: IFile[];
  fullName: string;
  specialization: string;
  biography?: string;
  thumbnail?: string;
  thumbnailUrl: string;
  coursesCount: 0;
  classesCount: 0;
  classHoursCount: 0;
  linkedInLink: string;
  facebookLink: string;
  instagramLink: string;
  twitterLink: string;
  backgroundPicture: string;
}

export class InstructorDto {
  fullName: string;
  picture: string;
}

export interface IFile {
  id: 0;
  icon: string;
  fileName: string;
  fileURI: string;
}
