import { Injectable } from '@angular/core';
import * as MobileDetect from 'mobile-detect';

@Injectable()
export class MobileService {
    public isMobile = false;
    constructor(
    ) {
        const md = new MobileDetect(window.navigator.userAgent);
        this.isMobile = md.phone() ? true : false;
    }
}
