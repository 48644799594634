// ABP legacy code
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpResponse, HttpErrorResponse, HttpHeaders, HttpInterceptor, HttpResponseBase } from '@angular/common/http';
import { switchMap, catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Router } from '@angular/router';

class AbpHttpConfiguration {
  defaultError = {
    message: 'An error has occurred!',
    details: 'Error details were not sent by server.',
  };

  handleTargetUrl(targetUrl) {
    if (!targetUrl) {
      location.href = '/';
    }

    location.href = targetUrl;
  }

  handleUnAuthorizedRequest = (messagePromise, targetUrl) => {
    if (messagePromise) {
      messagePromise.done(function() {
        this.handleTargetUrl(targetUrl || '/');
      });
    }
    this.handleTargetUrl(targetUrl || '/');
  }

  handleAbpResponse(response, ajaxResponse) {
    let newResponse: HttpResponseBase;
    if (ajaxResponse) {
      if (ajaxResponse.success) {
        newResponse = response.clone({
          body: ajaxResponse.result,
        });
        if (ajaxResponse.targetUrl) {
          this.handleTargetUrl(ajaxResponse.targetUrl);
        }
      }

      newResponse = response.clone({
        body: ajaxResponse.result,
      });

      if (response.status === 401) {
        this.handleUnAuthorizedRequest(null, ajaxResponse.targetUrl);
      }

      return newResponse;
    }
    return response;
  }

  getAbpAjaxResponseOrNull(response) {
    if (!response || !response.headers) {
      return null;
    }
    const contentType = response.headers.get('Content-Type');
    if (!contentType) {
      return null;
    }
    if (contentType.indexOf('application/json') < 0) {
      return null;
    }
    const responseObj = JSON.parse(JSON.stringify(response.body));

    return responseObj;
  }

  handleResponse(response) {
    const ajaxResponse = this.getAbpAjaxResponseOrNull(response);
    if (ajaxResponse == null) {
      return response;
    }
    return this.handleAbpResponse(response, ajaxResponse);
  }

  blobToText(blob) {
    return new Observable(function(observer) {
      if (!blob) {
        observer.next('');
        observer.complete();
      }

      const reader = new FileReader();
      reader.onload = function() {
        observer.next(this.result);
        observer.complete();
      };

      reader.readAsText(blob);
    });
  }
}

@Injectable()
export class AbpHttpInterceptor implements HttpInterceptor {
  configuration = new AbpHttpConfiguration();

  intercept = function(request, next) {
    const _this = this;
    const modifiedRequest = this.normalizeRequestHeaders(request);
    return next.handle(modifiedRequest).pipe(
      catchError(function(error) {
        return _this.handleErrorResponse(error);
      }),
      switchMap(function(event) {
        return _this.handleSuccessResponse(event);
      })
    );
  };

  normalizeRequestHeaders = function(request) {
    let modifiedHeaders = new HttpHeaders();
    modifiedHeaders = request.headers
      .set('Pragma', 'no-cache')
      .set('Cache-Control', 'no-cache')
      .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    modifiedHeaders = this.addXRequestedWithHeader(modifiedHeaders);
    modifiedHeaders = this.addAspNetCoreCultureHeader(modifiedHeaders);
    modifiedHeaders = this.addAcceptLanguageHeader(modifiedHeaders);
    return request.clone({
      headers: modifiedHeaders,
    });
  };
  addXRequestedWithHeader = function(headers) {
    if (headers) {
      headers = headers.set('X-Requested-With', 'XMLHttpRequest');
    }
    return headers;
  };
  addAspNetCoreCultureHeader = function(headers) {
    // TODO: extend this method with headers modify based on Culture
    return headers;
  };
  addAcceptLanguageHeader = function(headers) {
    // TODO: extend this method with headers modify based on Localization
    return headers;
  };

  handleSuccessResponse = function(event) {
    const self = this;
    if (event instanceof HttpResponse) {
      if (event.body instanceof Blob && event.body.type && event.body.type.indexOf('application/json') >= 0) {
        return self.configuration.blobToText(event.body).pipe(
          map(function(json: string) {
            const responseBody = json === 'null' ? {} : JSON.parse(json);
            const modifiedResponse = self.configuration.handleResponse(
              event.clone({
                body: responseBody,
              })
            );
            return modifiedResponse.clone({
              body: new Blob([JSON.stringify(modifiedResponse.body)], {
                type: 'application/json',
              }),
            });
          })
        );
      }
    }
    return of(event);
  };

  handleErrorResponse = function(error) {
    const _this = this;
    if (error.status === 401) {
      window.location.replace(location.origin + '/account/login');
      return throwError(error);
    }
    if (!(error.error instanceof Blob)) {
      return throwError(error);
    }
    return this.configuration.blobToText(error.error).pipe(
      switchMap(function(json: string) {
        const errorBody = json === '' || json === 'null' ? {} : JSON.parse(json);
        const errorResponse = new HttpResponse({
          headers: error.headers,
          status: error.status,
          body: errorBody,
        });

        const ajaxResponse = _this.configuration.getAbpAjaxResponseOrNull(errorResponse);

        if (ajaxResponse != null) {
          _this.configuration.handleAbpResponse(errorResponse, ajaxResponse);
        }

        return throwError(error);
      })
    );
  };

  itemExists = function(items, predicate) {
    for (let i = 0; i < items.length; i++) {
      if (predicate(items[i])) {
        return true;
      }
    }
    return false;
  };
}
