import { Component, Input, OnInit } from '@angular/core';
import { Step } from '@admin-panel/pages/courses/courses-dialog/courses-dialog.component';

@Component({
    selector: 'app-stepper',
    templateUrl: './stepper.components.html',
    styleUrls: ['./stepper.component.less']
})
export class StepperComponent {
    @Input('steps') steps: Step[];
    @Input('currentStep') currentStep: Step;

    constructor() {}
}
