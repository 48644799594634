import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { AccountsService } from '@admin-panel/pages/accounts/accounts.service';
import { SwitchAccountDto } from '@shared/auth/app-auth.service';
import { TenantService } from 'new_services/tenant.service';
import { GroupService } from '../../new_services/group.service';

@Component({
    templateUrl: './switch-account.component.html',
    styleUrls: ['./switch-account.less']
})
export class SwitchAccountComponent implements OnInit {
    loading: boolean;

    institutionList: ListItem[];
    accountList: ListItem[];

    institutionSelected;
    accountSelected;

    constructor(
        private dialog: MatDialogRef<any>,
        private tenantService: TenantService,
        private groupService: GroupService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) { }

    ngOnInit() {
        // this.loading = true;
        this.institutionList = this.data.institutionList;
    }

    onSelectChange(event) {
        if (!event) {
            this.accountList = [];
            return;
        }
    }

    onSubmit() {
        const dataToSubmit: SwitchAccountDto = {
            institutionId: this.institutionSelected,
        };

        this.onClose(dataToSubmit);
    }

    onClose(data?: SwitchAccountDto) {
        this.dialog.close(data);
    }
}

interface ListItem {
    id: number;
    name: string;
}
