import { Injectable } from '@node_modules/@angular/core';
import { HttpService } from './http.service';
import { dataURItoBlob, isDataURI } from '@shared/service-proxies/service-proxies.utils';

@Injectable({
    providedIn: 'root'
})
export class TenantService {
    constructor(private httpService: HttpService) {
    }

    postDownloadCsv(params) {
        this.httpService.post('/api/services/app/Tenant/DownloadCsv', params);
    }

    getGetAll(params) {
        return this.httpService.get('/api/services/app/Tenant/GetAll', {params});
    }

    getAllFilterdCount(params) {
        return this.httpService.get('/api/services/app/Tenant/GetAllFilteredTenantsCount', {params});
    }

    getGetTenant(params) {
        return this.httpService.get('/api/services/app/Tenant/Get', {params});
    }

    postCreateTenant(params) {
        const formData = new FormData();
        Object.keys(params).forEach((key) => {
            if (key !== 'Picture' && key !== 'MainLogo' && key !== 'LoginLogo') {
                formData.append(key, params[key]);
            }
        });

        const image = dataURItoBlob(
            this.getNormalizedPictureURI(params.Picture)
        );

        const mainLogo = dataURItoBlob(
            this.getNormalizedPictureURI(params.MainLogo)
        );
        const loginLogo = dataURItoBlob(
            this.getNormalizedPictureURI(params.LoginLogo)
        );

        image
            ? formData.append('Picture', image)
            : formData.append('RemovePicture', 'false');

        mainLogo
            ? formData.append('MainLogo', mainLogo)
            : formData.append('RemoveMainLogo', 'false');

        loginLogo
            ? formData.append('LoginLogo', loginLogo)
            : formData.append('RemoveLoginLogo', 'false');

        return this.httpService.post('/api/services/app/Tenant/Create', formData);
    }

    putUpdateTenant(params) {
        const formData = new FormData();
        Object.keys(params).forEach((key) => {
            if (key !== 'Picture' && key !== 'MainLogo' && key !== 'LoginLogo') {
                formData.append(key, params[key]);
            }
        });

        const image = dataURItoBlob(
            this.getNormalizedPictureURI(params.Picture)
        );

        const mainLogo = dataURItoBlob(
            this.getNormalizedPictureURI(params.MainLogo)
        );
        const loginLogo = dataURItoBlob(
            this.getNormalizedPictureURI(params.LoginLogo)
        );

        image
            ? formData.append('Picture', image)
            : formData.append('RemovePicture', 'false');

        mainLogo
            ? formData.append('MainLogo', mainLogo)
            : formData.append('RemoveMainLogo', 'false');

        loginLogo
            ? formData.append('LoginLogo', loginLogo)
            : formData.append('RemoveLoginLogo', 'false');

        return this.httpService.put('/api/services/app/Tenant/Update', formData);
    }

    putUpdateStatus(params) {
        return this.httpService.put('/api/services/app/Tenant/UpdateStatus', params);
    }

    putUpdateTenantName(params) {
        return this.httpService.put('/api/services/app/Tenant/UpdateTenantName', params);
    }

    deleteTenant(id) {
        return this.httpService.delete(`/api/services/app/Tenant/Delete?tenantId=${id}`);
    }


    dataURItoBlob = (dataString) => {
        if (!dataString) {
            return null;
        }

        if (isDataURI(dataString)) {
            const byteString = atob(dataString.split(',')[1]);
            const mimeString = dataString
                .split(',')[0]
                .split(':')[1]
                .split(';')[0];

            const dataArrayBlob = new ArrayBuffer(byteString.length);
            const UIntArrayData = new Uint8Array(dataArrayBlob);

            for (let i = 0; i < byteString.length; i++) {
                UIntArrayData[i] = byteString.charCodeAt(i);
            }

            const dataBlob = new Blob([UIntArrayData], { type: mimeString });

            return new File([dataBlob], 'instructorPhoto.png', {
                lastModified: new Date().getMilliseconds(),
                type: mimeString,
            });
        }

        return dataString;
    }

    getNormalizedPictureURI(pictureURI: string) {
        const isPictureFromServerRegExp = new RegExp(/(http(s?)):\/\//i);
        if (isPictureFromServerRegExp.test(pictureURI)) {
            return '';
        }
        return pictureURI;
    }

    isSubDomainExist(params) {
        return this.httpService.get('/api/services/app/Account/IsSubdomenAvailable', {params});
    }

}
