import { PipeTransform, Pipe } from '@angular/core';
import CodeSnippetHelper from '../helpers/CodeSnippetHelper';

@Pipe({ name: 'highlightSnippets'})
export class HighlightSnippetsPipe implements PipeTransform  {
  codeSnippet: CodeSnippetHelper;

  constructor() {
    this.codeSnippet = new CodeSnippetHelper();
  }

  transform(value: string) {
    if (value) {
      return this.codeSnippet.transformFullTextWithSnippets(value);
    }

    return value;
  }
}
