import { Subject, of, throwError } from 'rxjs';

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { API_BASE_URL } from '@shared/service-proxies/service-proxies';
import { ToastrService } from '@shared/toastr/toastr.service';

import {
  AccountItem,
  InstitutionsListItem,
  BillingFrequencyItem,
  GetAccountsParams,
  AccountNameField,
  ChangeAccountStatusParams,
  CreateAccountParams,
  EditAccountParams,
} from '@admin-panel/models/accounts.model';

import AccountTableItem from './table-template/table-item.model';
import { Institution } from '@admin-panel/models/institutions.model';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { AppAuthService } from '@shared/auth/app-auth.service';
import { LoginService } from '../../../account/login/login.service';

const makeHttpParams = (params) => {
  const obj = {};
  Object.keys(params).forEach((key) => {
    if (params[key] || params[key] === false) {
      obj[key] = params[key];
    }
  });
  return obj;
};

@Injectable()
export class AccountsService {
  public institutionsList: InstitutionsListItem[] = [];
  public billingFrequencyList: BillingFrequencyItem[] = [
    { id: 1, name: 'Monthly' },
    { id: 2, name: 'Annually' },
  ];

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private authService: AppAuthService,
    private loginService: LoginService,
    @Inject(API_BASE_URL) private baseUrl: string
  ) {}

  public getAccounts = (
    params: GetAccountsParams
  ): Subject<{ accounts: AccountItem[]; accountsCount: number }> => {

    switch (params.sortField) {
      case 'name': {
        params['Sort.Field'] = '0';
        break;
      }
      case 'status': {
        params['Sort.Field'] = '1';
        break;
      }
      case 'lastModified': {
        params['Sort.Field'] = 2;
        break;
      }
    }

    if (params.sortType && params.sortType === 'asc') {
      params['Sort.IsAsc'] = true;
    } else {
      params['Sort.IsAsc'] = 'false';
    }
    delete params.sortField;
    delete params.sortType;

    const subject: Subject<{
      accounts: AccountItem[];
      accountsCount: number;
    }> = new Subject();
    const _params = makeHttpParams({
      Keywords: this.handleKeywords(params.search),
      isActive: this.handleStatus(params.status),
      PageNumber: params.page + 1,
      MaxResultCount: params.itemsOnPage,
    });
    if (params['Sort.Field']) {
      _params['Sort.Field'] = params['Sort.Field'];
    }
    if (params['Sort.IsAsc']) {
      _params['Sort.IsAsc'] = params['Sort.IsAsc'];
    }
    this.http
      .get(this.baseUrl + '/api/services/app/Tenant/GetAll', {
        params: _params,
      })
      .subscribe(
        (response: { result }) =>
          subject.next({
            accounts: this.mapMultipleAccounts(response.result.items),
            accountsCount: response.result.totalCount,
          }),
        this.handleError
      );
    return subject;
  }

  public getAccountsByInstitution = (
    InstitutionId: number
  ): Subject<AccountNameField[]> => {
    const subject = new Subject<AccountNameField[]>();
    const _params = makeHttpParams({
      InstitutionId,
    });

    this.http
      .get(this.baseUrl + '/api/services/app/Tenant/GetTenantsByInstitution', {
        params: _params,
      })
      .subscribe(
        (response: { result }) =>
          subject.next(this.mapAccountNames(response.result)),
        this.handleError
      );
    return subject;
  }

  public getAccountSingle = (accountId: string): Subject<AccountItem> => {
    const subject: Subject<AccountItem> = new Subject();
    this.http
      .get(this.baseUrl + '/api/services/app/Tenant/Get', {
        params: { tenantId: accountId },
      })
      .subscribe(
        (response: { result }) =>
          subject.next(this.mapSingleAccount(response.result)),
        this.handleError
      );
    return subject;
  }

  public getFilterResultLength = (
    params: GetAccountsParams
  ): Subject<number> => {
    const subject: Subject<number> = new Subject();
    const _params = makeHttpParams({
      Keywords: params.search && params.search.split(' '),
      isActive: this.handleStatus(params.status),
      MaxResultCount: params.itemsOnPage,
    });
    this.http
      .get(
        this.baseUrl + '/api/services/app/Tenant/GetAllFilteredTenantsCount',
        { params: _params }
      )
      .subscribe(
        (response: { result }) => subject.next(response.result),
        this.handleError
      );
    return subject;
  }

  public getInstitutionsList = (): Subject<{
    institutions: Institution[];
    institutionsCount: number;
  }> => {
    const subject: Subject<{
      institutions: Institution[];
      institutionsCount: number;
    }> = new Subject();

    const params = {
      MaxResultCount: '100',
    };
    this.http
      .get(this.baseUrl + '/api/services/app/Institution/GetInstitutionNames', {
        params,
      })
      .subscribe(({ result }: any) => {
        subject.next({
          institutions: (result && result.items) || [],
          institutionsCount: result && result.totalCount,
        });
      }, this.handleError);

    return subject;
  }

  public createAccount = (
    params: CreateAccountParams
  ): Subject<AccountTableItem> => {
    const subject = new Subject<AccountTableItem>();
    const body = {
      name: params.name,
      institutionId: params.institutionId,
      subscriptionStartDate: this.getUTCFormattedDate(
        params.subscriptionStartDate
      ),
      subscriptionRenewalDate: this.getUTCFormattedDate(
        params.subscriptionEndDate
      ),
      billingFrequency: params.billingFrequency,
      maximumUsers: params.maxUsersCount,
      sendNotifications: params.sendNotifications
    };
    this.http
        .post(this.baseUrl + '/api/services/app/Tenant/Create', body)
        .subscribe(
            (response: { result: AccountTableItem }) => {
              this.authService.refreshTokenAuth().subscribe((res: any) => {
                localStorage.setItem('access', res.result.accessToken);
                localStorage.setItem('refresh', res.result.refreshToken);
                const authenticateResult = res.result;

                this.loginService.specialTokenUpdate(authenticateResult.accessToken,
                    authenticateResult.encryptedAccessToken,
                    authenticateResult.expireInSeconds,
                    authenticateResult.domain);
                subject.next(response.result);

              });
            }, err => {
              this.handleError(err);
              subject.next();
            }
        );
    return subject;
  }

  public editAccount = (
    params: EditAccountParams
  ): Subject<{ success: boolean }> => {
    const subject: Subject<{ success: boolean }> = new Subject();

    const body = {
      id: params.id,
      name: params.name,
      institutionId: params.institutionId,
      subscriptionStartDate: this.getUTCFormattedDate(
        params.subscriptionStartDate
      ),
      subscriptionRenewalDate: this.getUTCFormattedDate(
        params.subscriptionEndDate
      ),
      billingFrequency: params.billingFrequency,
      maximumUsers: params.maxUsersCount,
      sendNotifications: params.sendNotifications
    };
    this.http
      .put(this.baseUrl + '/api/services/app/Tenant/Update', body)
      .subscribe(
        (response: { success: boolean }) => subject.next(response),
        this.handleError);
    return subject;
  }

  public changeAccountStatus = (
    params: ChangeAccountStatusParams
  ): Subject<{ success: boolean }> => {
    const subject: Subject<{ success: boolean }> = new Subject();
    this.http
      .put(this.baseUrl + '/api/services/app/Tenant/UpdateStatus', params)
      .subscribe(
        (response: { success: boolean }) => subject.next(response),
        this.handleError
      );
    return subject;
  }

  public deleteAccount = (accountId: string): Subject<{ success: boolean }> => {
    const subject: Subject<{ success: boolean }> = new Subject();
    this.http
      .delete(this.baseUrl + '/api/services/app/Tenant/Delete', {
        params: { tenantId: accountId },
      })
      .subscribe(
        (response: { success: boolean }) => subject.next(response),
        this.handleError
      );
    return subject;
  }

  private mapMultipleAccounts = (accounts: any[]) =>
    accounts.map(this.mapSingleAccount)

  private mapSingleAccount = (account) => ({
    id: account.id,
    name: account.name,
    institution: account.institution,
    isActive: account.isActive,
    activeSince: new Date(account.lastActivationTime),
    billingFrequency: account.billingFrequency,
    subscriptionStartDate: new Date(account.subscriptionStartDate),
    subscriptionEndDate: new Date(account.subscriptionRenewalDate),
    usersCount: account.usersCount,
    maxUsersCount: account.maximumUsers,
    lastModified: new Date(account.lastModificationTime),
    sendNotifications: account.sendNotifications
  })

  private mapAccountNames = (accounts) =>
    accounts.map((account) => ({
      id: Number(account.id),
      name: account.name,
    }))

  private handleKeywords = (param: string): string[] =>
    typeof param === 'string'
      ? param.split(' ').filter((el) => el && el !== ' ')
      : []

  private handleStatus = (status: number) =>
    status === 1 ? true : status === 2 ? false : null

  private getUTCFormattedDate = (date: Date) =>
    date ? moment.utc(date).format() : null

  private handleError = (err: any) => {
    sweetAlert({
        title: 'Error',
        text: err.error.error && err.error.error.validationErrors && err.error.error.validationErrors.length > 0
            ? err.error.error.validationErrors[0].message
            : err.error.error.message
    }).then(() => {
    });
      return throwError(new Error(err.statusText));
  }
}
