import {
    Component,
    ViewChild,
    ElementRef,
    EventEmitter,
    OnChanges,
    Output,
    Input,
    OnInit
} from '@angular/core';
import { debounce } from 'lodash-es';

@Component({
    selector: 'app-select-search',
    templateUrl: './select-search.component.html',
    styleUrls: ['./select-search.styles.less']
})
export class SelectSearchComponent implements OnInit, OnChanges {
    @ViewChild('searchElement', { static: true }) searchElement: ElementRef;

    @Input() opened: boolean;
    @Input() entriesNumber: number;
    @Input() noEntriesText: string;
    @Input() placeholder: string;

    @Output() onSearch = new EventEmitter();

    value: string;

    onInputChangeDebounced = debounce(this.onInputChange, 300);

    ngOnInit() {
        this.setDefaultTemplateData();
    }

    ngOnChanges() {
        if (this.opened) {
            this.setFocus();
            return;
        }

        this.value = '';
    }

    onInputChange(search: string) {
        this.onSearch.emit(search);
    }

    setFocus() {
        setTimeout(() =>
            this.searchElement.nativeElement.focus(),
            250
        );
    }

    onClear() {
        this.value = '';
        this.onInputChangeDebounced('');
    }

    private setDefaultTemplateData() {
        this.noEntriesText = this.noEntriesText || 'Occasionally, no entries found.';
        this.placeholder = this.placeholder || 'Search';
        this.value = '';
    }
}
