import { Injectable } from '@angular/core';
import { ToastrService as Service } from 'ngx-toastr';
import { debounce } from 'lodash-es';
import { Text } from '@angular/compiler/src/i18n/i18n_ast';

@Injectable({
  providedIn: 'root',
})
export class ToastrService {
  public showErrorDebounced = debounce(this.showError, this.debounceTime);

  private hasTitle: boolean;

  private defaultOptions = {
    showDuration: 300,
    hideDuration: 300,
    timeOut: 3000,
    extendedTimeOut: 1000,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    enableHtml: true,
  };

  private options: any = {};

  constructor(private toastr: Service) {}

  private get debounceTime() {
    return 500;
  }

  public showSuccess(title: string, description = '', customOptions: any = {}) {
    const data = this.wrapText({ description, title });
    this.toastr.success(data.description, data.title, this.createOptions(customOptions));
  }

  public showInfo(title: string, description?: string, customOptions?: {} | any) {
    const data = this.wrapText({ description, title });
    this.toastr.info(data.description, data.title, this.createOptions(customOptions));
  }

  public showError(title: string, description?: string, customOptions?: {} | any) {
    const data = this.wrapText({ description, title });
    this.toastr.error(data.description, data.title, this.createOptions(customOptions));
  }

  private wrapText(text: toastData): toastData {
    if (text && text.description && text.description.length) {
      text.description = `<div class="custom-toast-description ${text.title ? 'with-title' : ''}">${
        text.description
      }</div>`;
    }
    if (text.title.length) {
      this.hasTitle = true;
    }
    return text;
  }

  private createOptions(customOptions: any = {}) {
    if (this.hasTitle) {
      customOptions.toastClass = 'ngx-toastr with-title';
    }
    return { ...this.defaultOptions, ...customOptions };
  }
}

type toastData = {
  description: string;
  title: string;
};
